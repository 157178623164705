import {
  AGENCY_GET_REQUEST,
  AGENCY_GET_SUCCESS,
  AGENCY_GET_FAILURE,
  AGENCY_CREATE_REQUEST,
  AGENCY_CREATE_SUCCESS,
  AGENCY_CREATE_FAILURE,
  AGENCY_AGENT_CREATE_REQUEST,
  AGENCY_AGENT_CREATE_FAILURE,
  AGENCY_AGENT_CREATE_SUCCESS
} from "../actions";

const baseState = {
  company: undefined,
  address: undefined,
  address2: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  phone: undefined,
  fax: undefined,
  email: undefined
};

export default function agency(state = { data: baseState, loading: false }, action) {
  switch (action.type) {
    case AGENCY_CREATE_REQUEST:
    case AGENCY_AGENT_CREATE_REQUEST:
    case AGENCY_GET_REQUEST:
      return { loading: true };
    case AGENCY_CREATE_SUCCESS:
    case AGENCY_AGENT_CREATE_SUCCESS:
    case AGENCY_GET_SUCCESS:
      return { ...baseState, loading: false, ...action.data };
    case AGENCY_CREATE_FAILURE:
    case AGENCY_AGENT_CREATE_FAILURE:
    case AGENCY_GET_FAILURE:
      return { loading: false, data: baseState, error: action.error };
    default:
      return state;
  }
}

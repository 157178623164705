import React from 'react'
import { DownOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown, Space } from 'antd'
import { AuthContextInterface } from '../use_auth'

interface UserDropdownProps {
    auth: AuthContextInterface
}
const UserDropdown: React.FC<UserDropdownProps> = ({ auth }) => {
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.antgroup.com"
                >
                    My profile
                </a>
            ),
        },
        {
            key: '2',
            danger: true,
            label: 'Sign out',
            onClick: auth.logout,
        },
    ]
    return (
        <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()} style={{ color: '#00BAC9' }}>
                <Space>
                    Hi {auth.username || 'Account'}
                    <DownOutlined rev={true} />
                </Space>
            </a>
        </Dropdown>
    )
}

export default UserDropdown

import React, { useEffect } from 'react'

import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BSField from '../components/shared_components/bs_field'
import { useAuth } from '../components/use_auth'
import { useLocation } from 'react-router-dom'

const valSchema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
})

const LoginPage = () => {
    const auth = useAuth()
    const location = useLocation()

    const fromPath = location.state ? location.state.from : '/'

    useEffect(() => {
        auth.autoLogin(fromPath)
    }, [auth])

    const handleLogin = ({ username, password }) => {
        auth.login(username, password, fromPath)
    }

    return (
        <div className="row">
            <div className="col-md-4 col-md-offset-4">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">Please sign in</h3>
                    </div>
                    <div className="panel-body">
                        <div className="form-group">
                            <Formik
                                validationSchema={valSchema}
                                initialValues={{ username: '', password: '' }}
                                onSubmit={handleLogin}
                            >
                                {({
                                    touched,
                                    errors,
                                    isValid,
                                    isSubmitting,
                                }) => (
                                    <Form>
                                        <BSField
                                            touched={touched.username}
                                            error={errors.username}
                                        >
                                            Username
                                            <Field
                                                name="username"
                                                type="text"
                                                className="form-control"
                                            />
                                        </BSField>
                                        <BSField
                                            touched={touched.password}
                                            error={errors.password}
                                        >
                                            Password
                                            <Field
                                                name="password"
                                                type="password"
                                                className="form-control"
                                            />
                                        </BSField>
                                        <button
                                            className="btn btn-success btn-block"
                                            type="submit"
                                            disabled={!isValid}
                                        >
                                            {isSubmitting && (
                                                <FontAwesomeIcon
                                                    icon="spinner"
                                                    spin
                                                />
                                            )}{' '}
                                            Login
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage

import {
    ARIA_V2_GET_REQUEST,
    ARIA_V2_GET_SUCCESS,
    ARIA_V2_GET_FAILURE,
    ARIA_V2_UPDATE_STATE,
    ARIA_V2_CREATE_REQUEST,
    ARIA_V2_CREATE_SUCCESS,
    ARIA_V2_CREATE_FAILURE,
    ARIA_V2_UPDATE_REQUEST,
    ARIA_V2_ADD_REQUEST,
    ARIA_V2_UPDATE_FAILURE,
    ARIA_V2_UPDATE_SUCCESS,
    ARIA_V2_FILE_UPLOAD_PARSE_REQUEST,
    ARIA_V2_FILE_UPLOAD_PARSE_SUCCESS,
    ARIA_V2_FILE_UPLOAD_PARSE_FAILURE,
    ARIA_V2_RESET_REQUEST,
    ARIA_V2_RESET_SUCCESS,
} from '../actions'

export default function ariav2(state = { loading: true }, action) {
    switch (action.type) {
        case ARIA_V2_GET_REQUEST:
        case ARIA_V2_CREATE_REQUEST:
        case ARIA_V2_UPDATE_REQUEST:
        case ARIA_V2_ADD_REQUEST:
        case ARIA_V2_RESET_REQUEST:
            return { loading: true }
        case ARIA_V2_FILE_UPLOAD_PARSE_REQUEST:
            return { ...state, fileLoading: true }
        case ARIA_V2_GET_SUCCESS:
        case ARIA_V2_CREATE_SUCCESS:
        case ARIA_V2_UPDATE_SUCCESS:
        case ARIA_V2_RESET_SUCCESS:
            return { loading: false, ...action.data }
        case ARIA_V2_GET_FAILURE:
        case ARIA_V2_CREATE_FAILURE:
        case ARIA_V2_UPDATE_FAILURE:
            return { loading: false, error: action.error }
        case ARIA_V2_FILE_UPLOAD_PARSE_FAILURE:
            return { ...state, fileLoading: false, error: action.error }
        case ARIA_V2_UPDATE_STATE:
            return { ...state.ariav2, loading: false, ...action.data }
        case ARIA_V2_FILE_UPLOAD_PARSE_SUCCESS:
            return {
                data: {
                    ...state.data,
                    ...action.data,
                },
                loading: false,
                fileLoading: false,
            }

        default:
            return state
    }
}

import {
  ILLUSTRATIONS_GET_REQUEST,
  PROPOSAL_ILLUSTRATIONS_GET_REQUEST,
  ILLUSTRATIONS_GET_FAILURE,
  PROPOSAL_ILLUSTRATIONS_GET_FAILURE,
  ILLUSTRATIONS_GET_SUCCESS,
  PROPOSAL_ILLUSTRATIONS_GET_SUCCESS,
  CLIENT_ILLUSTRATIONS_GET_REQUEST,
  CLIENT_ILLUSTRATIONS_GET_SUCCESS,
  CLIENT_ILLUSTRATIONS_GET_FAILURE
} from "../actions";

export default function illustrations(
  state = {
    loading: true, page: 0, perPage: 25, count: 0,
    pagination: { pageSize: 25, total: 0, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['25', '50', '100'] },
    sorted: undefined, data: []
  },
  action
) {
  switch (action.type) {
    case PROPOSAL_ILLUSTRATIONS_GET_REQUEST:
    case CLIENT_ILLUSTRATIONS_GET_REQUEST:
    case ILLUSTRATIONS_GET_REQUEST:
      return { loading: true, page: 0, perPage: 25, count: 0, sorted: undefined, data: [] };
    case PROPOSAL_ILLUSTRATIONS_GET_SUCCESS:
    case CLIENT_ILLUSTRATIONS_GET_SUCCESS:
    case ILLUSTRATIONS_GET_SUCCESS:
      return { loading: false, ...action.data };
    case PROPOSAL_ILLUSTRATIONS_GET_FAILURE:
    case CLIENT_ILLUSTRATIONS_GET_FAILURE:  
    case ILLUSTRATIONS_GET_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

import {
  CALC_CLIENTS_GET_REQUEST,
  CALC_CLIENTS_GET_SUCCESS,
  CALC_CLIENTS_GET_FAILURE,
} from "../actions";

export default function clients(state = {
  loading: false, count: 0, perPage: 25, page: 0,
  pagination: { pageSize: 25, total: 0, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['25', '50', '100'] }, data: [{}], sorted: undefined
}, action) {
  switch (action.type) {
    case CALC_CLIENTS_GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CALC_CLIENTS_GET_SUCCESS:
      return { loading: false, ...action.data };
    case CALC_CLIENTS_GET_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

import { FormikErrors, FormikTouched } from 'formik'
import React, { ReactElement } from 'react'
import { JsxElement } from 'typescript'

interface BSFieldProps {
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  children: HTMLLabelElement| JsxElement | HTMLElement | JSX.Element[] | undefined; 
  className?: string
}

const BSField = (props: BSFieldProps): ReactElement => {
  const {touched, error, children, className} = props;
  
  return (
    <div
      className={
        'form-group has-feedback ' +
        className +
        (touched ? (error ? ' has-error' : ' has-success') : '')
      }
    >
      {children}
      {touched &&
        !error && (
          <span className="glyphicon glyphicon-ok form-control-feedback" />
        )}
      {touched &&
        error && (
          <span className="glyphicon glyphicon-remove form-control-feedback" />
        )}
      {error && touched && <div className="help-block">{error}</div>}
    </div>
  );
};

export default BSField;

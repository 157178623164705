import { LeftOutlined } from '@ant-design/icons'
import { Card, Col, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    requestAgency,
    requestAgent,
    requestBucketReports,
    requestClient,
} from '../../../actions'
import ProfileTable from './ProfileTable'
import { useHistory, useParams } from 'react-router-dom'
const { Title, Text } = Typography
import moment from 'moment'

const Profile = (props: any) => {
    const params = useParams<{ type: string; id: string }>()
    const { type: paramType, id: paramId } = params
    const {
        agency,
        advisor,
        client,
        bucketReports,
        showProfile,
        selectedAgency,
        setShowProfile,
        setShowProfileForm,
        setSelectedAgency,
    } = props

    useEffect(() => {
        if (showProfile.for == 'agency') {
            props.requestAgency(showProfile.id)
        } else if (showProfile.for == 'advisor') {
            props.requestAgent(showProfile.id)
        } else if (showProfile.for == 'client') {
            props.requestClient(showProfile.id)
            props.requestBucketReports(showProfile.id)
        }
    }, [showProfile])

    const [showClientProfile, setShowClientProfile] = useState<
        number | undefined
    >(undefined)

    if (showProfile.for == 'agency' && agency.data) {
        return (
            <>
                <Row
                    align="bottom"
                    style={{ marginBottom: 10, cursor: 'pointer' }}
                >
                    <Col span={20}>
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {agency.data.company}{' '}
                            <Text type="secondary">Agency</Text>
                        </Title>
                    </Col>
                    <Col span={4}>
                        <Text
                            style={{ color: '#00BAC9', float: 'right' }}
                            onClick={() =>
                                setShowProfileForm({
                                    type: 'edit',
                                    for: 'agency',
                                    id: agency.data.id,
                                })
                            }
                        >
                            Edit
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col>{agency.data.email}</Col>
                </Row>
                <Row>
                    <Col>
                        {agency.data.address}, {agency.data.state},{' '}
                        {agency.data.postal}
                    </Col>
                </Row>
                <Row>
                    <Col>{agency.data.phone}</Col>
                </Row>
                <br />
                {/* <AdvisorClientsTable
                    advisorId={data.id}
                    setShowClientProfile={setShowClientProfile}
                    setAddClient={setAddClient}
                /> */}
                <ProfileTable
                    showTableFor={showProfile}
                    setShowProfile={setShowProfile}
                    setShowProfileForm={setShowProfileForm}
                    {...props}
                />
            </>
        )
    } else if (
        showProfile.for == 'advisor' &&
        !advisor.loading &&
        advisor.data
    ) {
        if (!selectedAgency && paramType === 'advisor' && paramId) {
            setSelectedAgency({
                id: parseInt(advisor.data.agency.id),
                name: advisor.data.agency.company,
            })
        }
        return (
            <>
                <Row
                    align="bottom"
                    style={{ marginBottom: 10, cursor: 'pointer' }}
                >
                    <Col span={20}>
                        <Title level={4} style={{ marginBottom: 0 }}>
                            {advisor.data.fullName}{' '}
                            <Text type="secondary">Advisor</Text>
                        </Title>
                    </Col>
                    <Col span={4}>
                        <Text
                            style={{ color: '#00BAC9', float: 'right' }}
                            onClick={() =>
                                setShowProfileForm({
                                    type: 'edit',
                                    for: 'advisor',
                                    id: advisor.data.id,
                                })
                            }
                        >
                            Edit
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Col>{advisor.data.email}</Col>
                </Row>
                <Row>
                    <Col>{advisor.data.phone}</Col>
                </Row>
                <Row>
                    <Col>{advisor.data.fax}</Col>
                </Row>
                <br />
                <ProfileTable
                    showTableFor={showProfile}
                    setShowProfile={setShowProfile}
                    setShowProfileForm={setShowProfileForm}
                    {...props}
                />
            </>
        )
    } else if (showProfile.for == 'client' && client.firstName) {
        const history = useHistory()

        if (!selectedAgency && paramType === 'client' && paramId) {
            setSelectedAgency({
                id: parseInt(client.agency.value),
                name: client.agency.label,
            })
        }

        return (
            <>
                <Row
                    onClick={() => setShowClientProfile(0)}
                    style={{ cursor: 'pointer' }}
                >
                    <Col>
                        <Title
                            level={4}
                            style={{ marginBottom: 0 }}
                            onClick={() =>
                                setShowProfile({
                                    id: client.selectedAgent.value,
                                    for: 'advisor',
                                })
                            }
                        >
                            <LeftOutlined rev={undefined} />
                        </Title>
                    </Col>
                    <Col span={22}>
                        <Title
                            level={5}
                            style={{ color: '#00BAC9', marginBottom: 0 }}
                            onClick={() =>
                                setShowProfile({
                                    id: client.selectedAgent.value,
                                    for: 'advisor',
                                })
                            }
                        >
                            {client.selectedAgent.label}
                        </Title>
                    </Col>
                    <Col>
                        <Text
                            style={{ marginLeft: 4, color: '#00BAC9' }}
                            onClick={() =>
                                setShowProfileForm({
                                    type: 'edit',
                                    for: 'client',
                                    id: client.id,
                                    advisorId: client.selectedAgent.value,
                                })
                            }
                        >
                            Edit
                        </Text>
                    </Col>
                </Row>
                <Row align="bottom">
                    <Col span={16}>
                        <Row align="bottom">
                            <Col>
                                <Title level={2} style={{ marginBottom: 0 }}>
                                    {client.firstName} {client.lastName}
                                </Title>
                            </Col>
                            <Col>
                                <Text
                                    type="secondary"
                                    style={{
                                        marginLeft: 4,
                                    }}
                                >
                                    Client
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        span={8}
                        style={{ textAlign: 'right', paddingRight: 30 }}
                    >
                        <Text type="secondary">
                            {selectedAgency ? selectedAgency.name : ''}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Card
                        style={{
                            width: '100%',
                            backgroundColor: '#FAFAFA',
                            borderStyle: 'solid',
                            borderWidth: '2px',
                        }}
                    >
                        <Row>
                            <Col span={8}>
                                {client.firstName} {client.lastName}
                            </Col>
                            <Col span={8}>{client.spouse}</Col>
                            <Col span={8}>
                                <Text type="secondary">{client.address}</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Text type="secondary">
                                    {client.gender == 2 ? 'Female' : 'Male'},{' '}
                                    {client.age}
                                </Text>
                            </Col>
                            <Col span={8}>
                                <Text type="secondary">
                                    {client.state} {client.postal}{' '}
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Text type="secondary">{client.email}</Text>
                            </Col>
                            <Col span={8}>
                                <Text type="secondary">{client.email}</Text>
                            </Col>
                            <Col span={8}>
                                <Text type="secondary">{client.phone}</Text>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={4}>
                                <Text type="secondary">Social security</Text>
                            </Col>
                            <Col span={3}>
                                <Text type="secondary">Pension</Text>
                            </Col>
                            <Col span={3}>
                                <Text type="secondary">Other</Text>
                            </Col>
                            <Col span={4}>
                                <Text type="secondary">Retirement plan</Text>
                            </Col>
                            <Col span={4}>
                                <Text type="secondary">Savings & Invs.</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={4}>
                                {client.socialSecurityAnnualIncome}
                            </Col>
                            <Col span={3}>{client.pensonAnnualIncome}</Col>
                            <Col span={3}>{client.investmentsAnnualIncome}</Col>
                            <Col span={4}>xxx</Col>
                            <Col span={4}>{client.savingsAnnualIncome}</Col>
                            <Col span={6}>xxx</Col>
                        </Row>
                    </Card>
                </Row>
                <br />
                <Row>
                    <Col span={4}>
                        <Title
                            level={5}
                            style={{ margin: 0, color: '#00BAC9' }}
                        >
                            Bucket reports
                        </Title>
                    </Col>
                    <Col span={20}>
                        <Text
                            type="secondary"
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                history.replace(
                                    `/bucket-tool/client/${client.id}`
                                )
                            }
                        >
                            New report
                        </Text>
                    </Col>
                </Row>
                <Row>
                    {bucketReports.data &&
                        bucketReports.data.map((report: any, index: number) => {
                            return (
                                <Card
                                    style={{
                                        width: '100%',
                                        borderStyle: 'solid',
                                        borderWidth: '2px',
                                    }}
                                    onClick={() =>
                                        history.replace(
                                            `/bucket-tool/client/${client.id}/report/${report.id}`
                                        )
                                    }
                                >
                                    <Row>
                                        <Col span={14}>
                                            <Text type="secondary">
                                                Report name
                                            </Text>
                                        </Col>
                                        <Col span={5}>Created</Col>
                                        <Col span={5}>Modified</Col>
                                    </Row>
                                    <Row>
                                        <Col span={14}>{report.name}</Col>
                                        <Col span={5}>
                                            {moment(report.createdOn).format(
                                                'MM/DD/YYYY LT'
                                            )}
                                        </Col>
                                        <Col span={5}>
                                            {moment(report.modifiedOn).format(
                                                'MM/DD/YYYY LT'
                                            )}
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col span={4}>
                                            <Text type="secondary">
                                                Index rate
                                            </Text>
                                        </Col>
                                        <Col span={3}>
                                            <Text type="secondary">IFA</Text>
                                        </Col>
                                        <Col span={3}>
                                            <Text type="secondary">
                                                Qualified
                                            </Text>
                                        </Col>
                                        <Col span={4}>
                                            <Text type="secondary">
                                                Qualified Funds
                                            </Text>
                                        </Col>
                                        <Col span={4}>
                                            <Text type="secondary">
                                                Federal tax
                                            </Text>
                                        </Col>
                                        <Col span={4}>
                                            <Text type="secondary">
                                                State tax
                                            </Text>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4}>x.xx%</Col>
                                        <Col span={3}>$xxx</Col>
                                        <Col span={3}>xxx</Col>
                                        <Col span={4}>$x.xx</Col>
                                        <Col span={4}>xx.xx%</Col>
                                        <Col span={4}>x.xx%</Col>
                                    </Row>
                                </Card>
                            )
                        })}
                </Row>
            </>
        )
    } else {
        return <Spin size="large" />
    }
}

const mapStateToProps = (state: any) => {
    return {
        agency: state.agency,
        advisor: state.agent,
        client: state.client,
        bucketReports: state.bucketReports,
    }
}

export default connect(mapStateToProps, {
    requestAgency,
    requestAgent,
    requestClient,
    requestBucketReports,
})(Profile)

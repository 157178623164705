import { Card, Col, Row, Space, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react'
import {
    BucketApproachEnum,
    ManuallyEditedEnum,
    SegmentType,
} from '../../containers/BucketToolPage'
const { Title, Text } = Typography

const AnnuityCard = ({
    position,
    initValues,
    handleUpdateSegment,
    handleRemove,
    bucketApproach,
    goalAmount,
    inflationPercentage,
    availableFunds,
    manuallyEdited,
}) => {
    let values = { ...initValues }
    delete values.type

    let calculatedMonthlyIncome = undefined
    if (
        availableFunds &&
        manuallyEdited !== ManuallyEditedEnum.monthlyIncome &&
        bucketApproach !== BucketApproachEnum.input_only
    ) {
        if (values.payoutMethod === 'income_rider') {
            const availableFundsParsed = parseFloat(availableFunds)
            const withdrawalRatePercentage =
                parseFloat(values.withdrawalRatePercentage) / 100
            const rateOfReturnPercentage =
                1 + parseFloat(values.rateOfReturnPercentage) / 100
            const deferralYears = parseInt(values.deferralYears)
            const premiumBonusPercentage =
                1 + parseFloat(values.premiumBonusPercentage) / 100

            // Algorithm provided in latest spec doc 9/18/23
            const annualIncome =
                availableFundsParsed *
                withdrawalRatePercentage *
                rateOfReturnPercentage ** deferralYears *
                premiumBonusPercentage

            const monthlyIncome = (annualIncome / 12).toFixed(2)
            if (monthlyIncome !== values.monthlyIncome) {
                calculatedMonthlyIncome = monthlyIncome
            }
        } else {
            if (
                values.withdrawalRatePercentage &&
                values.deferralYears &&
                values.rollupPercentage
            ) {
                const availableFundsParsed = parseFloat(availableFunds)
                const withdrawalRatePercentage =
                    parseFloat(values.withdrawalRatePercentage) / 100
                const rollupPercentage =
                    1 + parseFloat(values.rollupPercentage) / 100
                const deferralYears = parseInt(values.deferralYears)
                const premiumBonusPercentage =
                    1 + parseFloat(values.premiumBonusPercentage) / 100

                const annualIncome =
                    availableFundsParsed *
                    withdrawalRatePercentage *
                    rollupPercentage ** deferralYears *
                    premiumBonusPercentage

                const monthlyIncome = (annualIncome / 12).toFixed(2)
                if (monthlyIncome !== values.monthlyIncome) {
                    calculatedMonthlyIncome = monthlyIncome
                }
            }
        }
    }

    let calculatedinflationAdjustedMonthlyIncome = undefined

    function calculateAnnuityAdjMonthlyIncome() {
        //  income goal * (1+inflation rate)^(start year-2023)
        const inflationAdjusted = Math.round(
            parseFloat(goalAmount) *
                (1 +
                    parseFloat(inflationPercentage ? inflationPercentage : 0) /
                        100) **
                    (parseInt(values.startYear) - 2023)
        ).toFixed(2)

        if (inflationAdjusted !== values.inflationAdjustedMonthlyIncome) {
            // If none or NaN use monthlyIncome instead
            if (inflationAdjusted === 'NaN') {
                if (values.monthlyIncome) {
                    calculatedinflationAdjustedMonthlyIncome =
                        values.monthlyIncome
                } else {
                    calculatedinflationAdjustedMonthlyIncome =
                        calculatedMonthlyIncome
                }
            } else {
                calculatedinflationAdjustedMonthlyIncome = inflationAdjusted
            }
        }
    }

    if (
        manuallyEdited !== ManuallyEditedEnum.inflationAdjustedMonthlyIncome &&
        bucketApproach !== BucketApproachEnum.input_only
    ) {
        calculateAnnuityAdjMonthlyIncome()
    } else if (
        manuallyEdited !== ManuallyEditedEnum.inflationAdjustedMonthlyIncome &&
        bucketApproach === BucketApproachEnum.input_only
    ) {
        calculatedinflationAdjustedMonthlyIncome = values.monthlyIncome
    }
    function calculateTotalNeeded() {
        if (values.payoutMethod === 'income_rider') {
            // Start Value = ((Monthly Income Amount * 12) / Withdrawal Rate) / ((1 + Rollup Rate) ^
            // Deferral Years) / (1 + Bonus %)
            const totalNeeded = parseFloat(
                (parseFloat(
                    values.inflationAdjustedMonthlyIncome
                        ? values.inflationAdjustedMonthlyIncome
                        : calculatedinflationAdjustedMonthlyIncome
                ) *
                    12) /
                    (parseFloat(values.withdrawalRatePercentage) / 100) /
                    (1 + parseFloat(values.rollupPercentage) / 100) **
                        parseInt(values.deferralYears) /
                    (1 + parseFloat(values.premiumBonusPercentage) / 100)
            )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            return totalNeeded === 'NaN' ? 0 : totalNeeded
        } else if (values.payoutMethod === 'annuitization') {
            //                 Start Value = ((Monthly Income Amount * 12) / Withdrawal Rate) / ((1 + Rate of Return) ^
            // Deferral Years) / (1 + Bonus %)
            const totalNeeded = parseFloat(
                (parseFloat(
                    values.inflationAdjustedMonthlyIncome
                        ? values.inflationAdjustedMonthlyIncome
                        : calculatedinflationAdjustedMonthlyIncome
                ) *
                    12) /
                    (parseFloat(values.withdrawalRatePercentage) / 100) /
                    (1 + parseFloat(values.rateOfReturnPercentage) / 100) **
                        parseInt(values.deferralYears) /
                    (1 + parseFloat(values.premiumBonusPercentage) / 100)
            )
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            return totalNeeded === 'NaN' ? 0 : totalNeeded
        }
    }

    let startYear = values.startYear
    const initialStartYear = values.initial_start_year
    if (bucketApproach !== BucketApproachEnum.input_only) {
        if (
            values.deferralYears > 0 &&
            manuallyEdited !== ManuallyEditedEnum.startYear
        ) {
            let deferralYears = parseInt(values.deferralYears)
            startYear = initialStartYear + deferralYears
            if (values.startYear !== startYear) {
                handleUpdateSegment(position, 'startYear', startYear, true)
            }
        } else if (!values.deferralYears || values.deferralYears === 0) {
            startYear = initialStartYear
        }
    }

    if (
        values.payoutMethod === 'income_rider' &&
        manuallyEdited !== ManuallyEditedEnum.incomeYears
    ) {
        handleUpdateSegment(position, 'incomeYears', 100, true)
    } else if (
        values.payoutMethod === 'annuitization' &&
        manuallyEdited !== ManuallyEditedEnum.incomeYears &&
        values.incomeYears === 'Lifetime'
    ) {
        handleUpdateSegment(position, 'incomeYears', 0, true)
    }
    return (
        <>
            <Col className="gutter-row" span={6}>
                <Card
                    style={{
                        width: '100%',
                    }}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        <Row>
                            <Col span={20}>
                                <Title level={5}>Annuity</Title>
                            </Col>
                            <Col span={4}>
                                <CloseCircleOutlined
                                    style={{
                                        fontSize: '18px',
                                        color: 'red',
                                    }}
                                    onClick={() =>
                                        handleRemove(
                                            SegmentType.annuity,
                                            position
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        {bucketApproach === BucketApproachEnum.input_only ? (
                            <>
                                <Row>
                                    <Col>
                                        <div className="material-textfield">
                                            <input
                                                placeholder=""
                                                type="text"
                                                name="totalNeeded"
                                                onChange={(e) => {
                                                    handleUpdateSegment(
                                                        position,
                                                        'totalNeeded',
                                                        e.target.value
                                                    )
                                                }}
                                                value={values.totalNeeded}
                                                className="material-textfield-input annuityTotalNeeded"
                                            />
                                            <label
                                                style={{
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                Premium
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="material-textfield">
                                            <input
                                                placeholder=""
                                                type="text"
                                                name="inflationPercentage"
                                                onChange={(e) => {
                                                    handleUpdateSegment(
                                                        position,
                                                        'inflationPercentage',
                                                        e.target.value
                                                    )
                                                }}
                                                value={
                                                    values.inflationPercentage
                                                }
                                                className="material-textfield-input"
                                            />
                                            <label
                                                style={{
                                                    fontWeight: 'normal',
                                                }}
                                            >
                                                Inflation %
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <Text>Total needed</Text>
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: 'end',
                                        }}
                                    >
                                        <Text>
                                            $
                                            <span className="annuityTotalNeeded">
                                                {calculateTotalNeeded()}
                                            </span>
                                        </Text>
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <select
                                        name="payoutMethod"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'payoutMethod',
                                                e.target.value
                                            )
                                        }}
                                        value={values.payoutMethod}
                                        placeholder=""
                                        className="material-textfield-input"
                                    >
                                        <option value={'income_rider'}>
                                            Income rider
                                        </option>
                                        <option value={'annuitization'}>
                                            Annuitization
                                        </option>
                                    </select>
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Payout method
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="deferralYears"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'deferralYears',
                                                e.target.value
                                            )
                                        }}
                                        value={values.deferralYears}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Deferral years
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="startYear"
                                        disabled={true}
                                        // onChange={(e) => {
                                        //     handleUpdateSegment(
                                        //         position,
                                        //         'startYear',
                                        //         e.target.value
                                        //     )
                                        // }}
                                        value={values.startYear}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Start year
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="number"
                                        name="monthlyIncome"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'monthlyIncome',
                                                e.target.value,
                                                true
                                            )
                                        }}
                                        value={
                                            values.monthlyIncome
                                                ? values.monthlyIncome
                                                : calculatedMonthlyIncome
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Monthly income
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="number"
                                        name="incomeYears"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'incomeYears',
                                                e.target.value
                                            )
                                        }}
                                        value={values.incomeYears}
                                        className="material-textfield-input"
                                        disabled={
                                            values.payoutMethod ===
                                            'income_rider'
                                        }
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Income years
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="inflationAdjustedMonthlyIncome"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'inflationAdjustedMonthlyIncome',
                                                e.target.value,
                                                true
                                            )
                                        }}
                                        value={
                                            values.inflationAdjustedMonthlyIncome
                                                ? values.inflationAdjustedMonthlyIncome
                                                : calculatedinflationAdjustedMonthlyIncome
                                        }
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Inf. adj. income
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <select
                                        name="bonusType"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'bonusType',
                                                e.target.value
                                            )
                                        }}
                                        value={values.bonusType}
                                        placeholder=""
                                        className="material-textfield-input"
                                    >
                                        <option value={'income'}>Income</option>
                                        <option value={'premium'}>
                                            Premium
                                        </option>
                                    </select>
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Bonus type
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="bonusPercentage"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'bonusPercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={values.bonusPercentage}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Bonus %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="rollupPercentage"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'rollupPercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={values.rollupPercentage}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Rollup %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="rateOfReturnPercentage"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'rateOfReturnPercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={values.rateOfReturnPercentage}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Growth Rate %
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="material-textfield">
                                    <input
                                        placeholder=""
                                        type="text"
                                        name="withdrawalRatePercentage"
                                        onChange={(e) => {
                                            handleUpdateSegment(
                                                position,
                                                'withdrawalRatePercentage',
                                                e.target.value
                                            )
                                        }}
                                        value={values.withdrawalRatePercentage}
                                        className="material-textfield-input"
                                    />
                                    <label
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Withdraw rate %
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        {/* {bucketApproach === BucketApproachEnum.income_goal && ( */}

                        {/* )} */}
                        {bucketApproach ===
                            BucketApproachEnum.available_funds && (
                            <>
                                <Row>
                                    <Col span={12}>
                                        <Text>Available funds</Text>
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            textAlign: 'end',
                                        }}
                                    >
                                        <Text className="annuityAvailableFunds">
                                            ${availableFunds}
                                        </Text>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Space>
                </Card>
            </Col>
        </>
    )
}

export default AnnuityCard

import 'react-toastify/dist/ReactToastify.min.css'

import * as Sentry from '@sentry/react'
import { Col, ConfigProvider, Layout, Row } from 'antd'
import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { toast } from 'react-toastify'
import ProfileManagementPage from './ProfileManagementPage'
import NoMatch from '../components/no_match'
import PrivateRoute from '../components/private_route'
import { useAuth } from '../components/use_auth'
import { AGENCY } from '../constants'
import { useServiceWorker } from '../hooks/useServiceWorker'
// import ChangePassword from './ChangePassword'
import LoginPage from './LoginPage'
import { REACT_APP_VERSION } from '../config/env'

import logo from '../components/ProfileManagement/Retirement_optimized_logo.png'

import UserDropdown from '../components/ProfileManagement/UserDropdown'
import BucketToolPage from './BucketToolPage'

const { Header, Content, Footer } = Layout
// const { SubMenu } = Menu

function MainComponent() {
    const auth = useAuth()
    const { waitingWorker, showReload, reloadPage } = useServiceWorker()
    const toastId = React.useRef(null)

    useEffect(() => {
        if (showReload && waitingWorker) {
            toastId.current = toast.info(
                <div>
                    A new version of this page is available
                    <button onClick={() => reloadPage()}>REFRESH</button>
                </div>,
                {
                    autoClose: false,
                    position: 'bottom-right',
                    closeOnClick: false,
                }
            )
        } else {
            if (toastId.current) {
                toast.dismiss(toastId.current)
            }
        }
    }, [waitingWorker, showReload, reloadPage])

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#00BAC9',
                },
            }}
        >
            <Header
                style={{
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1.5px solid #9E9E9E',
                }}
            >
                <Row>
                    <Col span={12}>
                        <img
                            className="example-link-icon"
                            style={{ maxWidth: '15%' }}
                            src={logo}
                            alt="logo"
                        />
                    </Col>
                    <Col span={12}>
                        {auth.loggedIn && (
                            <div style={{ float: 'right' }}>
                                <UserDropdown auth={auth} />
                            </div>
                        )}
                    </Col>
                </Row>
            </Header>
            {/* <FullPageLoading /> */}
            <br />
            <Content>
                <ToastContainer />
                <Sentry.ErrorBoundary
                    fallback={({ error, componentStack, resetError }) => (
                        <PrivateRoute userRole={null}>
                            <div className="h1">
                                Uh oh! Something did not go as planned!
                            </div>
                            <div className="h3">
                                But don't worry, we are working on it!
                            </div>
                            <div>{error.toString()}</div>
                            <div>{componentStack}</div>
                            <button
                                onClick={() => {
                                    resetError()
                                }}
                            >
                                Click here to reset!
                            </button>
                        </PrivateRoute>
                    )}
                >
                    <Switch>
                        <Route path="/login" exact children={<LoginPage />} />
                        <Route
                            path="/"
                            exact
                            render={() => (
                                <PrivateRoute userRole={AGENCY}>
                                    <ProfileManagementPage active="/profiles" />
                                </PrivateRoute>
                            )}
                        />
                        <Route
                            path="/:type/:id"
                            exact
                            render={() => (
                                <PrivateRoute userRole={AGENCY}>
                                    <ProfileManagementPage active="/profiles" />
                                </PrivateRoute>
                            )}
                        />
                        <Route
                            path="/bucket-tool/client/:clientId"
                            exact
                            render={() => (
                                <PrivateRoute userRole={AGENCY}>
                                    <BucketToolPage active="/bucket-tool" />
                                </PrivateRoute>
                            )}
                        />
                        <Route
                            path="/bucket-tool/client/:clientId/report/:bucketId"
                            exact
                            render={() => (
                                <PrivateRoute userRole={AGENCY}>
                                    <BucketToolPage active="/bucket-tool" />
                                </PrivateRoute>
                            )}
                        />
                        {/* <Route
                                path="/dashboard"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={AGENCY}>
                                        <Dashboard active="/dashboard">
                                            <DashboardPage />
                                        </Dashboard>
                                    </PrivateRoute>
                                )}
                            /> */}
                        {/* <Route
                                path="/change-password"
                                exact
                                render={() => (
                                    <PrivateRoute userRole={null}>
                                        <ChangePassword />
                                    </PrivateRoute>
                                )}
                            />{' '} */}
                        <Route children={NoMatch} />
                    </Switch>
                </Sentry.ErrorBoundary>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                &copy; 2023 PeakPro Financial - All Rights Reserved - version{' '}
                {REACT_APP_VERSION}
            </Footer>
        </ConfigProvider>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}

const Main2 = connect(mapStateToProps)(MainComponent)
export default Main2

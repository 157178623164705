import * as api from "./api";
import { isEmpty } from "lodash";

export const calcClientService = {
  getAllCalcClients,
  getCalcClient,
  createCalcClient,
  updateCalcClient
};

function getAllCalcClients({ page, perPage, sorted }) {
  if (isEmpty(sorted)) {
    sorted = null;
  }
  return api
    .get("/api/v1/calc-clients/", {
      params: { page: page, per_page: perPage, sorted: sorted }
    })
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getCalcClient(client) {
  return api
    .get(`/api/v1/calc-clients/${client}`)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function createCalcClient(payload) {
  return api
    .post("/api/v1/calc-clients/", payload)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function updateCalcClient(clientId, payload) {
  return api
    .put(`/api/v1/calc-clients/${clientId}/`, payload)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { illustrationService } from '../services/illustration.service'

const {
    ILLUSTRATIONS_GET_REQUEST,
    successIllustrations,
    failedIllustrations,
    ILLUSTRATION_GET_REQUEST,
    successIllustration,
    failedIllustration,
    processErrorMessage,
    successAlert,
    ILLUSTRATION_UPDATE_REQUEST,
    failedUpdateIllustration,
    ILLUSTRATION_CREATE_REQUEST,
    failedCreateIllustration,
    ILLUSTRATION_FILE_UPLOAD_PARSE_REQUEST,
    successFileParse,
    failedFileParse,
    CLIENT_ILLUSTRATIONS_GET_REQUEST,
    successClientIllustrations,
    failedClientIllustrations,
} = actions

export function* getIllustrations() {
    while (true) {
        const { page, perPage, sorted } = yield take(ILLUSTRATIONS_GET_REQUEST)
        try {
            const response = yield call(
                illustrationService.getAllIllustrations,
                { page, perPage, sorted }
            )
            yield put(successIllustrations(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedIllustrations(error))
        }
    }
}

export function* getIllustration() {
    while (true) {
        const { illustrationId } = yield take(ILLUSTRATION_GET_REQUEST)
        try {
            const response = yield call(illustrationService.getIllustration, {
                illustrationId,
            })
            yield put(successIllustration(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedIllustration(error))
        }
    }
}

export function* updateIllustration() {
    while (true) {
        const payload = yield take(ILLUSTRATION_UPDATE_REQUEST)
        try {
            const response = yield call(
                illustrationService.updateIllustration,
                payload
            )
            yield put(successIllustration(response))
            yield put(successAlert('Illustration updated successfully'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdateIllustration(error))
        }
    }
}

export function* createIllustration() {
    while (true) {
        const { clientId, importData, history } = yield take(
            ILLUSTRATION_CREATE_REQUEST
        )
        try {
            const response = yield call(
                illustrationService.createIllustration,
                clientId,
                importData
            )
            history.push(`/illustrations/edit/${response.data.id}`)
            yield put(successAlert('Illustration created successfully'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateIllustration(error))
        }
    }
}

export function* uploadFileParse() {
    while (true) {
        const { payload } = yield take(ILLUSTRATION_FILE_UPLOAD_PARSE_REQUEST)
        try {
            const response = yield call(
                illustrationService.uploadParseFile,
                payload
            )
            yield put(successFileParse(response))
            yield put(successAlert('File parsed successfully'))
        } catch (error) {
            yield put(
                processErrorMessage(error || 'Error processing the file.')
            )
            yield put(failedFileParse(error))
        }
    }
}

export function* getClientIllustrations() {
    while (true) {
        const { clientId, page, perPage, sorted } = yield take(
            CLIENT_ILLUSTRATIONS_GET_REQUEST
        )
        try {
            const response = yield call(
                illustrationService.getClientIllustrations,
                clientId,
                page,
                perPage,
                sorted
            )
            yield put(successClientIllustrations(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedClientIllustrations(error))
        }
    }
}

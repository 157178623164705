import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { proposalService } from '../services/proposal.service'

const {
    PROPOSALS_GET_REQUEST,
    successProposals,
    failedProposals,
    PROPOSAL_GET_REQUEST,
    successProposal,
    failedProposal,
    processErrorMessage,
    CLIENT_PROPOSALS_GET_REQUEST,
    failedClientProposals,
    successClientProposals,
    PROPOSAL_CREATE_REQUEST,
    successCreateProposal,
    failedCreateProposal,
    PROPOSAL_ADD_REQUEST,
    failedAddProposal,
    PROPOSAL_UPDATE_REQUEST,
    failedUpdateProposal,
    PROPOSAL_DETAIL_REQUEST,
    successDetailProposal,
    failedDetailProposal,
    PROPOSAL_LINK_EMAIL_REQUEST,
    PROPOSAL_LINKS_GET_REQUEST,
    successProposalLinks,
    failedProposalLinks,
    PROPOSAL_LINK_VIEWS_GET_REQUEST,
    successProposalLinkViews,
    failedProposalLinkViews,
    successAlert,
    PROPOSAL_LINK_EMAILS_REQUEST,
    successProposalLinkEmails,
    failedProposalLinkEmails,
} = actions

export function* getProposals() {
    while (true) {
        const { page, perPage, sorted } = yield take(PROPOSALS_GET_REQUEST)
        try {
            const response = yield call(proposalService.getAllProposals, {
                page,
                perPage,
                sorted,
            })
            yield put(successProposals(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedProposals(error))
        }
    }
}

export function* getProposal() {
    while (true) {
        const { proposalId } = yield take(PROPOSAL_GET_REQUEST)
        try {
            const response = yield call(proposalService.getProposal, {
                proposalId,
            })
            yield put(successProposal(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedProposal(error))
        }
    }
}

export function* getDetailProposal() {
    while (true) {
        const { proposalId } = yield take(PROPOSAL_DETAIL_REQUEST)
        try {
            const response = yield call(proposalService.getDetailProposal, {
                proposalId,
            })
            yield put(successDetailProposal(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedDetailProposal(error))
        }
    }
}

export function* getClientProposals() {
    while (true) {
        const { clientId, page, perPage, sorted } = yield take(
            CLIENT_PROPOSALS_GET_REQUEST
        )
        try {
            const response = yield call(
                proposalService.getClientProposals,
                clientId,
                page,
                perPage,
                sorted
            )
            yield put(successClientProposals(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedClientProposals(error))
        }
    }
}

export function* createProposal() {
    while (true) {
        const { illustrationId } = yield take(PROPOSAL_CREATE_REQUEST)
        try {
            const response = yield call(proposalService.createProposal, {
                illustrationId,
            })
            yield put(successCreateProposal(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateProposal(error))
        }
    }
}

export function* addProposal() {
    while (true) {
        const payload = yield take(PROPOSAL_ADD_REQUEST)
        try {
            const response = yield call(proposalService.addProposal, payload)
            payload.history.push(`/proposals/${response.data.id}`)
            yield put(successAlert('Proposal created'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAddProposal(error))
        }
    }
}

export function* updateProposal() {
    while (true) {
        const payload = yield take(PROPOSAL_UPDATE_REQUEST)
        try {
            const response = yield call(proposalService.updateProposal, payload)
            payload.history.push(`/proposals/${response.data.id}`)
            yield put(successAlert('Proposal updated'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdateProposal(error, payload))
        }
    }
}

export function* sendProposalLinkByEmail() {
    while (true) {
        const { proposalId, email } = yield take(PROPOSAL_LINK_EMAIL_REQUEST)
        try {
            yield call(proposalService.sendProposalLinkByEmail, {
                proposalId,
                email,
            })
            const email_str = email.toEmails.join('; ')
            yield put(successAlert(`Proposal link email sent to ${email_str}`))
        } catch (error) {
            yield put(processErrorMessage(error))
        }
    }
}

export function* getProposalLinkEmails() {
    while (true) {
        yield take(PROPOSAL_LINK_EMAILS_REQUEST)
        try {
            const response = yield call(proposalService.getProposalLinkEmails)
            yield put(successProposalLinkEmails(response))
        } catch (error) {
            console.debug(error)
            yield put(failedProposalLinkEmails(error))
        }
    }
}

export function* getProposalLinks() {
    while (true) {
        const params = yield take(PROPOSAL_LINKS_GET_REQUEST)
        try {
            const response = yield call(
                proposalService.getProposalLinks,
                params
            )
            yield put(successProposalLinks(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedProposalLinks(error))
        }
    }
}

export function* getProposalLinkViews() {
    while (true) {
        const params = yield take(PROPOSAL_LINK_VIEWS_GET_REQUEST)
        try {
            const response = yield call(
                proposalService.getProposalLinkViews,
                params
            )
            yield put(successProposalLinkViews(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedProposalLinkViews(error))
        }
    }
}

import { Card, Col, Row, Space, Typography, Upload } from 'antd'
import { CloseCircleOutlined, InboxOutlined } from '@ant-design/icons'
import React from 'react'
import { SegmentType } from '../../containers/BucketToolPage'
const { Title } = Typography

const IULCard = ({
    position,
    initValues,
    handleUpdateSegment,
    handleRemove,
    requestIULFileParse,
}) => {
    let values = { ...initValues }
    delete values.type
    let startAge = 0
    let endAge = 0
    let annualIncomeSum = 0
    let premiumsSum = 0

    if (values.ages) {
        startAge = values.ages[0]
        endAge = values.ages[values.ages.length - 1]
    }

    if (values.annualIncomes) {
        annualIncomeSum = Math.round(
            values.annualIncomes.reduce((acc, current) => acc + current, 0)
        )
    }
    if (values.premiums) {
        premiumsSum = Math.round(
            values.premiums.reduce((acc, current) => acc + current, 0)
        )
    }
    return (
        <>
            <Col className="gutter-row" span={6}>
                <Card
                    style={{
                        width: '100%',
                    }}
                >
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: 'flex' }}
                    >
                        {!startAge && (
                            <Row>
                                <div className="dropbox" style={{ width: 150 }}>
                                    <Upload.Dragger
                                        name="files"
                                        beforeUpload={(file) => {
                                            requestIULFileParse(
                                                [file],
                                                position
                                            )
                                            return false
                                        }}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">
                                            Click or drag file to this area to
                                            upload
                                        </p>
                                    </Upload.Dragger>
                                </div>
                            </Row>
                        )}
                        <Row>
                            <Col span={20}>
                                <Title level={5}>Life</Title>
                            </Col>
                            <Col span={4}>
                                <CloseCircleOutlined
                                    style={{ fontSize: '18px', color: 'red' }}
                                    onClick={() =>
                                        handleRemove(SegmentType.iul, position)
                                    }
                                />
                            </Col>
                        </Row>
                        {startAge !== 0 && (
                            <>
                                <Row>
                                    <Col>Start age: {startAge}</Col>
                                    <Col>End age: {endAge}</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Annual income sum:{' '}
                                        {new Intl.NumberFormat().format(
                                            annualIncomeSum
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Premium sum:{' '}
                                        {new Intl.NumberFormat().format(
                                            premiumsSum
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        Family Benefits initial:{' '}
                                        {new Intl.NumberFormat().format(
                                            values.familyBenefits[0]
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Space>
                </Card>
            </Col>
        </>
    )
}

export default IULCard

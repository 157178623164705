import { AxiosError } from 'axios'
// import LogRocket from 'logrocket'
// import * as Sentry from '@sentry/react'
import React, { useState, useContext, createContext } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Auth } from '../index.d'
import authService from '../services/auth.service'
import { REACT_APP_VERSION } from '../config/env'

export interface AuthContextInterface {
    loggedIn: boolean
    username: string
    roles: string[]
    token: string
    refreshToken: string
    login: (username: string, password: string, fromPath: string) => void
    logout: () => void
    autoLogin: (fromPath: string) => void
    changePassword: (data: {
        currentPassword: string
        password: string
        confirm: string
    }) => void
}

const AuthContext = createContext<AuthContextInterface>(
    {} as AuthContextInterface
)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuthProvider()
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}

const useAuthProvider = (): AuthContextInterface => {
    const history = useHistory()
    // const flagsmith = useFlagsmith()
    const [auth, setAuth] = useState<Auth>({
        loggedIn: false,
        username: '',
        roles: [],
        token: '',
        refreshToken: '',
    })

    const login = (
        username: string,
        password: string,
        fromPath: string
    ): void => {
        authService.authorize({ username, password }).then(async (response) => {
            switch (response.status) {
                case 'ok':
                    localStorage.setItem('token', response.token)
                    localStorage.setItem('refreshToken', response.refreshToken)
                    // flagsmith.identify(response.username)
                    // LogRocket.identify(response.username, {
                    //     roles: JSON.stringify(
                    //         response.roles.map((r: { name: string }) => r.name)
                    //     ),
                    //     version: REACT_APP_VERSION || 'dev',
                    // })
                    // Sentry.setUser({
                    //     email: response.username,
                    //     roles: JSON.stringify(
                    //         response.roles.map((r: { name: string }) => r.name)
                    //     ),
                    //     version: REACT_APP_VERSION || 'dev',
                    // })
                    setAuth({
                        loggedIn: true,
                        username: response.username,
                        roles: response.roles.map(
                            (r: { name: string }) => r.name
                        ),
                        token: response.token,
                        refreshToken: response.refreshToken,
                    })
                    history.push(fromPath)
                    break
                case 'ko':
                    toast.error(response.message)
                    break
                default:
                    toast.error(response.message)
                    break
            }
        })
    }

    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        // flagsmith.logout()
        setAuth({
            loggedIn: false,
            username: '',
            roles: [],
            token: '',
            refreshToken: '',
        })
    }

    const autoLogin = (fromPath: string) => {
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refreshToken')
        if (token && refreshToken) {
            authService
                .verify()
                .then((response) => {
                    switch (response.status) {
                        case 'ok':
                            // flagsmith.identify(response.username)
                            setAuth({
                                loggedIn: true,
                                username: response.username,
                                roles: response.roles.map(
                                    (r: { name: string }) => r.name
                                ),
                                token: token,
                                refreshToken: refreshToken,
                            })
                            history.push(fromPath)
                            break
                        default:
                            localStorage.removeItem('token')
                            localStorage.removeItem('refreshToken')
                            // flagsmith.logout()
                            setAuth({
                                loggedIn: false,
                                username: '',
                                roles: [],
                                token: '',
                                refreshToken: '',
                            })
                            break
                    }
                })
                .catch((error: AxiosError) => {
                    toast.error(error.message)
                })
        }
    }

    const changePassword = (data: {
        currentPassword: string
        password: string
        confirm: string
    }) => {
        return authService.changePassword(data)
    }

    return {
        loggedIn: auth.loggedIn,
        username: auth.username,
        roles: auth.roles,
        token: auth.token,
        refreshToken: auth.refreshToken,
        login,
        logout,
        autoLogin,
        changePassword,
    } as AuthContextInterface
}

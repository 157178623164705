import {
    IUL_FILE_UPLOAD_PARSE_REQUEST,
    IUL_FILE_UPLOAD_PARSE_FAILURE,
    IUL_FILE_UPLOAD_PARSE_SUCCESS,
} from '../actions'

export default function iulParseFile(state = { loading: false }, action) {
    switch (action.type) {
        case IUL_FILE_UPLOAD_PARSE_REQUEST:
            return { ...state, fileLoading: true }
        case IUL_FILE_UPLOAD_PARSE_FAILURE:
            return { ...state, fileLoading: false, error: action.error }
        case IUL_FILE_UPLOAD_PARSE_SUCCESS:
            return {
                data: {
                    ...state.data,
                    ...action.data,
                },
                loading: false,
                fileLoading: false,
            }
        default:
            return state
    }
}

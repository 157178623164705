import {
  INCOME_COMP_CREATE_FAILURE,
  INCOME_COMP_CREATE_REQUEST,
  INCOME_COMP_CREATE_SUCCESS,
  INCOME_COMP_GET_FAILURE,
  INCOME_COMP_GET_REQUEST,
  INCOME_COMP_GET_SUCCESS,
  INCOME_COMP_UPDATE_FAILURE,
  INCOME_COMP_UPDATE_REQUEST,
  INCOME_COMP_UPDATE_SUCCESS,
  INCOME_COMP_RESET_REQUEST,
  INCOME_COMP_RESET_SUCCESS,
  INCOME_COMP_SET_CLIENT_REQUEST,
  INCOME_COMP_SET_CLIENT_SUCCESS
} from '../actions'
import { BASE_IC_DATA } from '../components/shared_components/constants'

export default function incomeComp(state = { loading: true, data: BASE_IC_DATA }, action=undefined) {
  switch (action.type) {
    case INCOME_COMP_UPDATE_SUCCESS:
    case INCOME_COMP_CREATE_SUCCESS:
    case INCOME_COMP_GET_SUCCESS:
    case INCOME_COMP_RESET_SUCCESS:
        return { loading: false, data: action.data };
    case INCOME_COMP_UPDATE_FAILURE:
    case INCOME_COMP_CREATE_FAILURE:
    case INCOME_COMP_GET_FAILURE:
      return { loading: false, error: action.error };
    case INCOME_COMP_UPDATE_REQUEST:
    case INCOME_COMP_CREATE_REQUEST:
    case INCOME_COMP_GET_REQUEST:
      return { ...state, loading: true };
    case INCOME_COMP_SET_CLIENT_SUCCESS:
      return {
        data: {
          ...state.data,
          customer: action.data,
          customerId: action.data.id,
          customerAge: action.data.age,
          customerFilingStatus: action.data.filingStatus
        },
        loading: false
      };
    case INCOME_COMP_SET_CLIENT_REQUEST:
      return { data: { ...state.data, customer: undefined }, loading: true };
    case INCOME_COMP_RESET_REQUEST:
    default:
      return state;
  }
}

import {
    INCOME_COMP_TAX_CALC_UPDATE_FAILURE,
    INCOME_COMP_TAX_CALC_UPDATE_REQUEST,
    INCOME_COMP_TAX_CALC_UPDATE_SUCCESS
} from '../actions'

export default function taxCalcStatus(state, action) {
  switch (action.type) {
    case INCOME_COMP_TAX_CALC_UPDATE_REQUEST:
      return { isSaving: true, isInError: false, ...state }
    case INCOME_COMP_TAX_CALC_UPDATE_SUCCESS:
      return { isSaving: false, isInError: false, ...state }
    case INCOME_COMP_TAX_CALC_UPDATE_FAILURE:
      return { isSaving: false, isInError: true, ...state }
    default:
      return { isSaving: false, isInError: false, ...state }
  }
}

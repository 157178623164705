import {
  CLIENT_GET_REQUEST,
  CLIENT_GET_SUCCESS,
  CLIENT_GET_FAILURE,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAILURE
} from "../actions";

export default function client(state = { loading: true }, action) {
  switch (action.type) {
    case CLIENT_GET_REQUEST:
      return { loading: true };
    case CLIENT_GET_SUCCESS:
      return { loading: false, ...action.data };
    case CLIENT_GET_FAILURE:
      return { error: action.error };
    case CLIENT_CREATE_REQUEST:
      return { loading: true };
    case CLIENT_CREATE_SUCCESS:
      return { loading: false, ...action.data };
    case CLIENT_CREATE_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
}

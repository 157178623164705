import React from 'react'
import ReactDOM from 'react-dom'
// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'
// import LogRocket from 'logrocket'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCheckCircle,
    faChevronLeft,
    faEdit,
    faEye,
    faPlusSquare,
    faSpinner,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

import App from './containers/App'
import * as serviceWorker from './serviceWorkerRegistration'
import './ReactotronConfig'
// import './index.css'
import './new-index.css'
// import { SENTRY_DSN, REACT_APP_VERSION, ENVIRONMENT } from './config/env'

/*
LogRocket.init('xzxsrg/retirementoptimized')

Sentry.init({
    dsn: SENTRY_DSN,
    release: REACT_APP_VERSION,
    environment: ENVIRONMENT,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
})

LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL)
    })
})*/

library.add(
    faSpinner,
    faEye,
    faEdit,
    faPlusSquare,
    faChevronLeft,
    faCheckCircle,
    faTimesCircle
)

const NeedStorage = () => {
    return (
        <div>
            <h3>Localstorage is required for this app to function properly</h3>
        </div>
    )
}

if (typeof Storage !== 'undefined') {
    ReactDOM.render(<App />, document.getElementById('root'))
} else {
    ReactDOM.render(<NeedStorage />, document.getElementById('root'))
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register()

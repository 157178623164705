import { applyMiddleware, compose, createStore } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import LogRocket from 'logrocket'
import Reactotron from '../ReactotronConfig'
import rootReducer from '../reducers'
import sagas from '../sagas'

const sagaMonitor = Reactotron.createSagaMonitor()
const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

let store

if (process.env.NODE_ENV === 'production') {
    store = createStore(
        rootReducer,
        {},
        applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware())
    )
} else {
    store = createStore(
        rootReducer,
        {},
        compose(
            applyMiddleware(sagaMiddleware, logger),
            Reactotron.createEnhancer()
        )
    )
}

sagaMiddleware.run(sagas)

export default store

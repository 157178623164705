import * as api from './api'
import { isEmpty } from 'lodash'

export const ariaV2Service = {
    getAllAriaV2s,
    getClientAriaV2s,
    getAriaV2,
    addAriaV2,
    updateAriaV2,
    uploadParseAriaV2File,
    getAriaV2StaticPDFs,
}

function getClientAriaV2s(client, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/aria-v2/`, {
            params: {
                page: page,
                per_page: perPage,
                sorted: sorted,
                client: client,
            },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAllAriaV2s({ page, perPage, sorted }) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get('/api/v1/aria-v2/', {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAriaV2({ proposalId }) {
    return api
        .get(`/api/v1/aria-v2/${proposalId}`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function addAriaV2(payload) {
    return api
        .post('/api/v1/aria-v2/', payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function updateAriaV2(payload) {
    return api
        .put(`/api/v1/aria-v2/${payload.proposalId}/`, payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

async function uploadParseAriaV2File(files) {
    if (files.length === 0) api.handleError('Please select a valid file')
    if (
        files[0].type !== 'text/csv' &&
        files[0].type !== 'application/vnd.ms-excel' &&
        files[0].type !==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
        api.handleError(
            `Can only parse CSV or Excel files - file of type: ${files[0].type}`
        )
    const data = new FormData()
    await data.append('file', files[0], files[0].name)
    return api
        .post('/api/v1/aria-v2/parse-file/', data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAriaV2StaticPDFs() {
    return api
        .get(`/api/v1/aria-v2/static-pdfs/`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

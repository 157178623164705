import {
  PROPOSALS_GET_REQUEST,
  CLIENT_PROPOSALS_GET_REQUEST,
  PROPOSALS_GET_FAILURE,
  CLIENT_PROPOSALS_GET_FAILURE,
  PROPOSALS_GET_SUCCESS,
  CLIENT_PROPOSALS_GET_SUCCESS,
  AGENT_PROPOSALS_GET_REQUEST,
  AGENT_PROPOSALS_GET_SUCCESS,
  AGENT_PROPOSALS_GET_FAILURE,
  PROPOSAL_LINKS_GET_SUCCESS,
  PROPOSAL_LINKS_GET_REQUEST,
  PROPOSAL_LINKS_GET_FAILURE,
  PROPOSAL_LINK_VIEWS_GET_SUCCESS,
  PROPOSAL_LINK_VIEWS_GET_FAILURE,
  PROPOSAL_LINK_VIEWS_GET_REQUEST,
  PROPOSAL_LINK_EMAILS_SUCCESS
} from "../actions";

const baseState = {
  loading: true, page: 0, perPage: 25, count: 0,
  pagination: { pageSize: 25, total: 0, showSizeChanger: true, showQuickJumper: true, pageSizeOptions: ['25', '50', '100'] },
  sorted: undefined, data: [{}], links: [{ createdBy: {} }], link_views: [], emails: []
};

export default function proposals(state = baseState, action) {
  switch (action.type) {
    case CLIENT_PROPOSALS_GET_REQUEST:
    case AGENT_PROPOSALS_GET_REQUEST:
    case PROPOSAL_LINKS_GET_REQUEST:
    case PROPOSAL_LINK_VIEWS_GET_REQUEST:
    case PROPOSALS_GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CLIENT_PROPOSALS_GET_SUCCESS:
    case AGENT_PROPOSALS_GET_SUCCESS:
    case PROPOSALS_GET_SUCCESS:
      return {
        ...action.data,
        loading: false
      };
    case CLIENT_PROPOSALS_GET_FAILURE:
    case AGENT_PROPOSALS_GET_FAILURE:
    case PROPOSALS_GET_FAILURE:
    case PROPOSAL_LINKS_GET_FAILURE:
    case PROPOSAL_LINK_VIEWS_GET_FAILURE:
      return { ...baseState, loading: false, error: action.error };

    case PROPOSAL_LINKS_GET_SUCCESS:
      return {
        page: action.data.page,
        perPage: action.data.perPage,
        count: action.data.count,
        links: action.data.data,
        loading: false
      };
    case PROPOSAL_LINK_VIEWS_GET_SUCCESS:
      return {
        page: action.data.page,
        perPage: action.data.perPage,
        count: action.data.count,
        link_views: action.data.data,
        loading: false
      };
    case PROPOSAL_LINK_EMAILS_SUCCESS:
      return {
        emails: action.data.emails,
      };
    default:
      return state;
  }
}

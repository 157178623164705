import { SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Input, MenuProps, Row, Tabs } from 'antd'
import React, { useState } from 'react'
type TabPosition = 'left' | 'right' | 'top' | 'bottom'

const AgenciesTabs = (props: any) => {
    const { data, loading, error, setSelectedAgency, selectedAgency } = props

    const [tabPosition, setTabPosition] = useState<TabPosition>('left')
    const [searchValue, setSearchValue] = useState('')
    const filtered = searchValue
        ? data.filter((agency: any) => {
              if (
                  agency.company
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
              ) {
                  return true
              }

              return false
          })
        : data
    const dataMapped = filtered.map((agency: any) => {
        return {
            label: agency.company,
            key: agency.id.toString(),
            // children: (
            //     <AdvisorsClientsTabs
            //         agencyId={agency.id}
            //         setShowProfile={setShowProfile}
            //     />
            // ),
        }
    })

    return (
        <Tabs
            onTabClick={(key, value) => {
                setSelectedAgency({
                    id: parseInt(key),
                    name: data.find((agency: any) => parseInt(key) == agency.id)
                        .company,
                })
            }}
            tabBarExtraContent={{
                left: (
                    <Input
                        placeholder="Refine agencies"
                        prefix={<SearchOutlined rev={undefined} />}
                        onChange={(event) => {
                            setSearchValue(event.target.value)
                        }}
                    />
                ),
            }}
            tabPosition={tabPosition}
            items={dataMapped}
        />
    )
}

export default AgenciesTabs

import {
  AGENTS_GET_REQUEST,
  AGENTS_GET_SUCCESS,
  AGENTS_GET_FAILURE,
  AGENCY_AGENTS_GET_REQUEST,
  AGENCY_AGENTS_GET_SUCCESS,
  AGENCY_AGENTS_GET_FAILURE
} from "../actions";

export default function proposals(state = { loading: true }, action) {
  switch (action.type) {
    case AGENCY_AGENTS_GET_REQUEST:
    case AGENTS_GET_REQUEST:
      return { loading: true };
    case AGENCY_AGENTS_GET_SUCCESS:
    case AGENTS_GET_SUCCESS:
      return { loading: false, ...action.data };
    case AGENCY_AGENTS_GET_FAILURE:
    case AGENTS_GET_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { calcClientService } from '../services/calcClient.service'

const {
  CALC_CLIENTS_GET_REQUEST,
  CALC_CLIENTS_GET_SUCCESS,
  CALC_CLIENTS_GET_FAILURE,
  CALC_CLIENT_GET_REQUEST,
  CALC_CLIENT_GET_SUCCESS,
  CALC_CLIENT_GET_FAILURE,
  CALC_CLIENT_UPDATE_REQUEST,
  CALC_CLIENT_CREATE_REQUEST,
  successUpdateCalcClient,
  failedUpdatedCalcClient,
  successCreateCalcClient,
  failedCreateCalcClient,
  successAlert,
  processErrorMessage
} = actions;

export function* getCalcClients() {
  while (true) {
    const { page, perPage, sorted } = yield take(CALC_CLIENTS_GET_REQUEST);
    try {
      const response = yield call(calcClientService.getAllCalcClients, { page, perPage, sorted });
      yield put({ type: CALC_CLIENTS_GET_SUCCESS, ...response });
    } catch (error) {
      yield put(processErrorMessage(error));
      yield put({ type: CALC_CLIENTS_GET_FAILURE, error });
    }
  }
}

export function* getCalcClient() {
  while (true) {
    const { clientId } = yield take(CALC_CLIENT_GET_REQUEST);
    try {
      const response = yield call(calcClientService.getCalcClient, clientId);
      yield put({ type: CALC_CLIENT_GET_SUCCESS, ...response.data });
    } catch (error) {
      yield put(processErrorMessage(error));
      yield put({ type: CALC_CLIENT_GET_FAILURE, error });
    }
  }
}

export function* updateCalcClient() {
  while (true) {
    const { clientId, payload } = yield take(CALC_CLIENT_UPDATE_REQUEST);
    try {
      const response = yield call(calcClientService.updateCalcClient, clientId, payload);
      yield put(successUpdateCalcClient(response));
      yield put(successAlert("Client updated successfully"));
    } catch (error) {
      yield put(processErrorMessage(error));
      yield put(failedUpdatedCalcClient(error));
    }
  }
}

export function* createCalcClient() {
  while (true) {
    const { payload } = yield take(CALC_CLIENT_CREATE_REQUEST);
    try {
      const response = yield call(calcClientService.createCalcClient, payload);
      yield put(successCreateCalcClient(response));
      yield put(successAlert("Client created successfully"));
    } catch (error) {
      yield put(processErrorMessage(error));
      yield put(failedCreateCalcClient(error));
    }
  }
}

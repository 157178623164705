import {
    ARIA_V2_STATIC_PDFS_GET_REQUEST,
    ARIA_V2_STATIC_PDFS_GET_SUCCESS,
    ARIA_V2_STATIC_PDFS_GET_FAILURE,
} from '../actions'

export default function ariaV2StaticPDFs(
    state = { loading: true, data: [] },
    action
) {
    switch (action.type) {
        case ARIA_V2_STATIC_PDFS_GET_REQUEST:
            return { loading: true, data: [] }
        case ARIA_V2_STATIC_PDFS_GET_SUCCESS:
            return { loading: false, ...action.data }
        case ARIA_V2_STATIC_PDFS_GET_FAILURE:
            return { loading: false, data: action.data }
        default:
            return state
    }
}

import {
  AGENT_GET_REQUEST,
  AGENT_GET_SUCCESS,
  AGENT_GET_FAILURE,
  AGENT_CREATE_REQUEST,
  AGENT_CREATE_SUCCESS,
  AGENT_CREATE_FAILURE,
  AGENTS_GET_REQUEST,
  AGENTS_GET_SUCCESS,
  AGENTS_GET_FAILURE
} from "../actions";

export default function agent(state = { loading: true }, action) {
  switch (action.type) {
    case AGENTS_GET_REQUEST:
    case AGENT_GET_REQUEST:
      return { loading: true };
    case AGENTS_GET_SUCCESS:
    case AGENT_GET_SUCCESS:
      return { loading: false, ...action.data };
    case AGENTS_GET_FAILURE:
    case AGENT_GET_FAILURE:
      return { loading: false, error: action.error };
    case AGENT_CREATE_REQUEST:
      return { loading: true };
    case AGENT_CREATE_SUCCESS:
      return { loading: false, ...action.data };
    case AGENT_CREATE_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

import { call, put, take } from 'redux-saga/effects'

import * as actions from '../actions'
import { ariaV2Service } from '../services/ariav2.service'

const {
    ARIA_V2S_GET_REQUEST,
    successAriaV2s,
    failedAriaV2s,
    ARIA_V2_GET_REQUEST,
    successAriaV2,
    failedAriaV2,
    processErrorMessage,
    CLIENT_ARIA_V2S_GET_REQUEST,
    failedClientAriaV2s,
    successClientAriaV2s,
    ARIA_V2_CREATE_REQUEST,
    successCreateAriaV2,
    failedCreateAriaV2,
    ARIA_V2_ADD_REQUEST,
    failedAddAriaV2,
    ARIA_V2_RESET_REQUEST,
    successResetAriaV2,
    ARIA_V2_UPDATE_REQUEST,
    failedUpdateAriaV2,
    ARIA_V2_PDFS_GET_REQUEST,
    successAriaV2PDFs,
    failedAriaV2PDFs,
    ARIA_V2_STATIC_PDFS_GET_REQUEST,
    successAriaV2StaticPDFs,
    failedAriaV2StaticPDFs,
    ARIA_V2_FILE_UPLOAD_PARSE_REQUEST,
    successAriaV2FileParse,
    failedAriaV2FileParse,
    successAlert,
} = actions

export function* getAriaV2s() {
    while (true) {
        const { page, perPage, sorted } = yield take(ARIA_V2S_GET_REQUEST)
        try {
            const response = yield call(ariaV2Service.getAllAriaV2s, {
                page,
                perPage,
                sorted,
            })
            yield put(successAriaV2s(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAriaV2s(error))
        }
    }
}

export function* getAriaV2() {
    while (true) {
        const { proposalId } = yield take(ARIA_V2_GET_REQUEST)
        try {
            const response = yield call(ariaV2Service.getAriaV2, { proposalId })
            yield put(successAriaV2(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAriaV2(error))
        }
    }
}

export function* getClientAriaV2s() {
    while (true) {
        const { clientId, page, perPage, sorted } = yield take(
            CLIENT_ARIA_V2S_GET_REQUEST
        )
        try {
            const response = yield call(
                ariaV2Service.getClientAriaV2s,
                clientId,
                page,
                perPage,
                sorted
            )
            yield put(successClientAriaV2s(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedClientAriaV2s(error))
        }
    }
}

export function* createAriaV2() {
    while (true) {
        const { client } = yield take(ARIA_V2_CREATE_REQUEST)
        try {
            const response = {
                data: {
                    data: {
                        customerId: client.id,
                        agentName: client.selectedAgent.label,
                        clientName: client.fullName,
                        clientAge: client.currentAge,
                        clientGender: client.gender === 1 ? 'Male' : 'Female',
                        indexCreditingRate: 0.0,
                        initialFaceAmount: 0,
                        qualified: false,
                        qualifiedFundsAmount: 0,
                        incomeStartAge: client.currentAge,
                        federalTaxRate: 30.0,
                        stateTaxRate: 0.0,
                        policyData: [],
                    },
                },
            }
            yield put(successCreateAriaV2(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateAriaV2(error))
        }
    }
}

export function* addAriaV2() {
    while (true) {
        const payload = yield take(ARIA_V2_ADD_REQUEST)
        try {
            const response = yield call(ariaV2Service.addAriaV2, payload)
            payload.history.push(`/aria-v2/${response.data.id}`)
            yield put(successAlert('AriaV2 created'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAddAriaV2(error))
        }
    }
}

export function* updateAriaV2() {
    while (true) {
        const payload = yield take(ARIA_V2_UPDATE_REQUEST)
        try {
            const response = yield call(ariaV2Service.updateAriaV2, payload)
            payload.history.push(`/aria-v2/${response.data.id}`)
            yield put(successAlert('AriaV2 updated'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdateAriaV2(error, payload))
        }
    }
}

export function* getAriaV2PDFs() {
    while (true) {
        const params = yield take(ARIA_V2_PDFS_GET_REQUEST)
        try {
            const response = yield call(ariaV2Service.getAriaV2PDFs, params)
            yield put(successAriaV2PDFs(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAriaV2PDFs(error))
        }
    }
}

export function* uploadAriaV2FileParse() {
    while (true) {
        const { payload, values } = yield take(
            ARIA_V2_FILE_UPLOAD_PARSE_REQUEST
        )
        try {
            const response = yield call(
                ariaV2Service.uploadParseAriaV2File,
                payload
            )
            yield put(
                successAriaV2FileParse({
                    data: { ...values, ...response.data.data },
                })
            )
            yield put(successAlert('File parsed successfully'))
        } catch (error) {
            yield put(
                processErrorMessage(error || 'Error processing the file.')
            )
            yield put(failedAriaV2FileParse(error))
        }
    }
}

export function* resetAriaV2FileParse() {
    const { values } = yield take(ARIA_V2_RESET_REQUEST)
    yield put(
        successResetAriaV2({
            data: {
                data: {
                    ...values,
                    indexCreditingRate: 0.0,
                    initialFaceAmount: 0,
                    policyData: [],
                },
            },
        })
    )
}

export function* getAriaV2StaticPDFs() {
    while (true) {
        const params = yield take(ARIA_V2_STATIC_PDFS_GET_REQUEST)
        try {
            const response = yield call(
                ariaV2Service.getAriaV2StaticPDFs,
                params
            )
            yield put(successAriaV2StaticPDFs(response))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedAriaV2StaticPDFs(error))
        }
    }
}

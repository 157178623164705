import React from 'react'
import { Provider } from 'react-redux'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import store from '../store'
import Main from './Main'
import { AuthProvider } from '../components/use_auth'

const App = () => {
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Provider store={store}>
                <Router>
                    <AuthProvider>
                        <Main />
                    </AuthProvider>
                </Router>
            </Provider>
        </QueryClientProvider>
    )
}

export default App

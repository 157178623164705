import {
    AGENCIES_AGENTS_GET_REQUEST,
    AGENCIES_AGENTS_GET_SUCCESS,
    AGENCIES_AGENTS_GET_FAILURE,
} from '../actions'

export default function agenciesAgents(state = { loading: true }, action) {
    switch (action.type) {
        case AGENCIES_AGENTS_GET_REQUEST:
            return { loading: true }
        case AGENCIES_AGENTS_GET_SUCCESS:
            return { loading: false, ...action.data }
        case AGENCIES_AGENTS_GET_FAILURE:
            return { loading: false, error: action.error }
        default:
            return state
    }
}

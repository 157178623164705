import { Button, Card, Col, Input, Row, Space, Spin, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {
    requestBucketReportSegments,
    requestBucketReports,
    requestClient,
    requestCreateBucketReport,
    requestUpdateBucketReport,
    successCreateBucketReport,
    requestIULFileParse,
} from '../actions'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
const { Search } = Input
const { Title, Text } = Typography
import { Formik, Form } from 'formik'
import VaultCard from '../components/BucketTool/VaultCard'
import moment from 'moment'
import IULCard from '../components/BucketTool/IULCard'
import LongTermCareCard from '../components/BucketTool/LongTermCareCard'
import AnnuityCard from '../components/BucketTool/AnnuityCard'
import * as yup from 'yup'
import { useAuth } from '../components/use_auth'

const onSearch = (value: any) => console.log(value)

const incomeGoalApproachValidationSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Report name must be at least 3 characters long.')
        .max(50)
        .required('Report name is required.'),
    approach: yup.string().max(20).required('Approach is required'),
    goalAmount: yup.number().required('Goal amount is required'),
    inflationPercentage: yup.number().optional(),
    availableBalance: yup.number().optional(),
    customer: yup.string().required('Client ID is required'),
})

const availableFundsValidationSchema = yup.object().shape({
    name: yup
        .string()
        .min(3, 'Report name must be at least 3 characters long.')
        .max(50)
        .required('Report name is required.'),
    approach: yup.string().max(20).required('Approach is required'),
    availableBalance: yup.number().optional(),
    customer: yup.string().required('Client ID is required'),
})

export enum SegmentType {
    'annuity',
    'vault',
    'longTermCare',
    'iul',
}

export enum BucketApproachEnum {
    'income_goal' = 'income_goal',
    'available_funds' = 'available_funds',
    'input_only' = 'input_only',
}

export enum ManuallyEditedEnum {
    none,
    monthlyIncome,
    inflationAdjustedMonthlyIncome,
    startYear,
    incomeYears,
}

const BucketToolPage = (props: any) => {
    const history = useHistory()
    const { token } = useAuth()
    const params = useParams<{ clientId: string; bucketId: string }>()
    const { clientId, bucketId } = params
    const {
        client,
        bucketReports,
        bucketReportSegments,
        bucketReport,
        iulParseFile,
    } = props

    const [segments, setSegments] = useState<any[]>([])
    const [iulsToSave, setIulsToSave] = useState<any[]>([])
    const [iulsToDelete, setIulsToDelete] = useState<any[]>([])
    const [bucketReportForm, setBucketReportForm] = useState<any>(undefined)
    const [manuallyEditedAnnuity, setManuallyEditedAnnuity] =
        useState<ManuallyEditedEnum>(ManuallyEditedEnum.none)
    const [bucketApproach, setBucketApproach] = useState('')
    const [endingBalance, setEndingBalance] = useState(0)
    const [availableBalance, setAvailableBalance] = useState<
        number | undefined
    >(undefined)

    useEffect(() => {
        props.requestClient(clientId)
        props.requestBucketReports(clientId)
    }, [])

    useEffect(() => {
        if (bucketReports.data && bucketReports.data.length > 0 && bucketId) {
            const report = bucketReports.data.find(
                (report: any) => report.id == bucketId
            )
            props.requestBucketReportSegments(bucketId)
            setBucketReportForm(report)
        }
    }, [bucketReports])

    useEffect(() => {
        let loadedSegments: any[] = []
        if (bucketReportSegments.vaults) {
            bucketReportSegments.vaults.forEach((vault: any) =>
                loadedSegments.push({ type: SegmentType.vault, ...vault })
            )
        }
        if (bucketReportSegments.longTermCares) {
            bucketReportSegments.longTermCares.forEach((longTermCare: any) =>
                loadedSegments.push({
                    type: SegmentType.longTermCare,
                    ...longTermCare,
                })
            )
        }
        if (bucketReportSegments.iuls) {
            bucketReportSegments.iuls.forEach((iul: any) =>
                loadedSegments.push({
                    type: SegmentType.iul,
                    ...iul,
                })
            )
        }
        if (bucketReportSegments.annuities) {
            bucketReportSegments.annuities.forEach((annuity: any) =>
                loadedSegments.push({
                    type: SegmentType.annuity,
                    ...annuity,
                })
            )
        }

        setSegments(
            loadedSegments.sort((a, b) => (a.position > b.position ? 1 : -1))
        )
    }, [bucketReportSegments])

    useEffect(() => {
        if (bucketReport.data) {
            setBucketReportForm(bucketReport.data)
        }
    }, [bucketReport])

    useEffect(() => {
        if (!iulParseFile.loading && iulParseFile.data) {
            // Attach last uploaded IUL file to last IUL
            // const segment = iuls.find((iul) => iul.position == iuls.length)
            // segment['totalPremium'] = iulParseFile.data.totalPremium
            // segment['totalAccountValue'] = iulParseFile.data.totalAccountValue
            // segment['totalDeathBenefit'] = iulParseFile.data.totalDeathBenefit
            // segment['totalLiquidity'] = iulParseFile.data.totalLiquidity
            setIulsToSave([iulParseFile.data.iulId])
        }
    }, [iulParseFile])

    if (client && !client.loading) {
        function handleUpdateSegment(
            position: number,
            which: string,
            value: any,
            manuallyEdited?: boolean
        ) {
            segments[position][which] = value

            if (manuallyEdited && which == 'monthlyIncome') {
                setManuallyEditedAnnuity(ManuallyEditedEnum.monthlyIncome)
            } else if (
                manuallyEdited &&
                which == 'inflationAdjustedMonthlyIncome'
            ) {
                setManuallyEditedAnnuity(
                    ManuallyEditedEnum.inflationAdjustedMonthlyIncome
                )
            } else if (manuallyEdited && which == 'startYear') {
                setManuallyEditedAnnuity(ManuallyEditedEnum.startYear)
            } else if (manuallyEdited && which == 'incomeYears') {
                setManuallyEditedAnnuity(ManuallyEditedEnum.incomeYears)
            } else {
                setManuallyEditedAnnuity(ManuallyEditedEnum.none)
            }

            if (which === 'startingBalance') {
                setEndingBalance(
                    calculateSummaryEndingBalance(availableBalance)
                )
            }

            if (which === 'totalPremium') {
                setEndingBalance(
                    calculateSummaryEndingBalance(availableBalance)
                )
            }

            if (which == 'deferralYears') {
                if (value) {
                    if (segments[position]['initial_start_year']) {
                        segments[position]['startYear'] =
                            parseInt(segments[position]['initial_start_year']) +
                            parseInt(value)
                    } else {
                        const annuities =
                            segments.find(
                                (segment) =>
                                    segment.type === SegmentType.annuity
                            ) || []
                        if (annuities.length > 0) {
                            segments[position]['startYear'] =
                                parseInt(
                                    annuities[annuities.length - 1].startYear
                                ) + parseInt(value)
                        } else {
                            segments[position]['startYear'] =
                                moment().year() + parseInt(value)
                        }
                    }
                }
            }

            setSegments(segments)
        }

        function handleRemove(segmentType: SegmentType, position: number) {
            const removedSegment = segments.splice(
                segments.findIndex(
                    (segment) =>
                        segment.type == segmentType &&
                        segment.position == position
                ),
                1
            )
            setSegments(
                segments.map((segment, index) => {
                    segment.position = index
                    return segment
                })
            )

            if (segmentType === SegmentType.iul) {
                setIulsToDelete([...iulsToDelete, removedSegment[0].id])
            }
        }

        function calculateAnnuityStartYear(): number {
            if (segments.length > 0) {
                const annuities =
                    segments.find(
                        (segment) => segment.type === SegmentType.annuity
                    ) || []
                if (annuities.length > 0) {
                    const lastStartYear =
                        annuities[annuities.length - 1].startYear
                    return lastStartYear
                } else {
                    return moment().year()
                }
            }
            return moment().year()
        }

        function calculateAnnuityMonthlyIncome(
            goalAmount: any,
            inflationPercentage: any
        ): number {
            if (inflationPercentage) {
                const annuities = segments.find(
                    (segment) => segment.type === SegmentType.annuity
                )
                const deferralYears = annuities[annuities.length - 1]
                    ? annuities[annuities.length - 1].deferralYears
                    : 0
                // Monthly Income Amount = year 1 value (input) * (1 + Inflation Rate) ^ Deferral Years
                // (5000 * 1.03 ** 5).toFixed(2)
                return parseFloat(
                    (
                        parseFloat(goalAmount) *
                        (1 + parseFloat(inflationPercentage) / 100) **
                            parseInt(deferralYears)
                    ).toFixed(2)
                )
            } else {
                return goalAmount
            }
        }

        function calculateSummaryTotalRequired() {
            //total needed is the sum of total required + total IUL premium + LTC premium + vault starting balance

            let totalRequiredSum = 0

            if (segments.length > 0) {
                segments.forEach((segment) => {
                    if (segment.type === SegmentType.annuity) {
                        if (segment.totalNeeded) {
                            totalRequiredSum += parseFloat(segment.totalNeeded)
                        }
                    } else if (
                        segment.type === SegmentType.iul ||
                        segment.type === SegmentType.longTermCare
                    ) {
                        if (segment.totalPremium) {
                            totalRequiredSum += parseFloat(segment.totalPremium)
                        }
                    } else if (segment.type === SegmentType.vault) {
                        if (segment.startingBalance) {
                            totalRequiredSum += parseFloat(
                                segment.startingBalance
                            )
                        }
                    }
                })
            }

            return totalRequiredSum
        }

        function calculateSummaryEndingBalance(availableBalance: any) {
            let endingBalance = availableBalance
            if (bucketApproach == BucketApproachEnum.income_goal) {
                // Ending balance = Would this be all of the annuities "monthly income" added together?
                const annuities = segments.find(
                    (segment) => segment.type === SegmentType.annuity
                )
                annuities.forEach((annuity: any) => {
                    if (
                        bucketReportForm &&
                        bucketReportForm.inflationPercentage
                    ) {
                        endingBalance += parseFloat(
                            annuity.inflationAdjustedMonthlyIncome
                        )
                    } else {
                        annuity.monthlyIncome
                            ? (endingBalance += parseFloat(
                                  annuity.monthlyIncome
                              ))
                            : 0
                    }
                })
            } else if (bucketApproach == BucketApproachEnum.available_funds) {
                // if not a annuity segment first,
                // then subtract total premium and starting balance from Available funds
                if (segments.length > 0 && !('payoutMethod' in segments[0])) {
                    endingBalance = availableBalance
                    segments.forEach((segment) => {
                        if ('startingBalance' in segment) {
                            endingBalance -= segment.startingBalance
                        } else if ('totalPremium' in segment) {
                            endingBalance -= segment.totalPremium
                        }

                        /*else if ('payoutMethod' in segment) {
                            segment.monthlyIncome
                                ? (endingBalance += parseFloat(
                                      segment.monthlyIncome
                                  ))
                                : 0
                        }*/
                    })
                }
            }
            return endingBalance
        }

        let areAllAnnuityRequiredFieldsFilled = false
        function calculateSummaryYearsOfIncome() {
            // Years of income = all of the annuities "income years" field added together
            let income = 0
            let filledCount = 0
            if (segments.length > 0) {
                const annuities =
                    segments.find(
                        (segment) => segment.type === SegmentType.annuity
                    ) || []
                if (annuities.length > 0) {
                    annuities.forEach((annuity: any) => {
                        if (annuity.totalNeeded) {
                            filledCount += 1
                        }

                        if (annuity.incomeYears) {
                            income += parseInt(annuity.incomeYears)
                        }
                    })
                }

                if (filledCount === annuities.length) {
                    areAllAnnuityRequiredFieldsFilled = true
                }
            }
            return income
        }

        function calculateAnnuityAvailableFunds() {
            const annuities = segments.find(
                (segment) => segment.type === SegmentType.annuity
            )
            return endingBalance / annuities.length
        }

        const downloadPDFv2 = (reportId: any) => {
            window.open(
                // document.location.protocol +
                //     '//' +
                //     document.location.host +
                // `http://127.0.0.1/` + // (local)
                `https://api-staging.incomeanalyzer.com/` +
                    `api/v1/bucket-reports/${reportId}/pdf?jwt=${token}`,
                '_blank',
                'noreferrer'
            )
        }

        return (
            <>
                <Row>
                    <Col span={1}></Col>
                    <Col span={16}>
                        <Title level={5} style={{ margin: 0 }}>
                            <span
                                style={{ cursor: 'pointer' }}
                                className="primary-color"
                                onClick={() =>
                                    history.replace(
                                        `/agency/${client.agency.value}`
                                    )
                                }
                            >
                                {client.agency.label}
                            </span>{' '}
                            {'>'}{' '}
                            <span
                                style={{ cursor: 'pointer' }}
                                className="primary-color"
                                onClick={() =>
                                    history.replace(
                                        `/advisor/${client.selectedAgent.value}`
                                    )
                                }
                            >
                                {client.selectedAgent.label}
                            </span>{' '}
                            {'>'}{' '}
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.replace(`/client/${client.id}`)
                                }
                            >
                                {client.fullName}
                            </span>
                        </Title>
                    </Col>

                    <Col span={1}></Col>
                    <Col span={6}>
                        <Search
                            placeholder="Agency, advisor, or client"
                            onSearch={onSearch}
                            enterButton
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={6}></Col>
                    <Col span={12}>
                        {' '}
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            disabled={!bucketReportForm}
                        >
                            Preview
                        </Button>
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            disabled={!bucketReportForm}
                        >
                            Duplicate
                        </Button>
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            disabled={!bucketReportForm}
                        >
                            Email client report
                        </Button>
                        <Button
                            type="link"
                            size="middle"
                            className="primary-color"
                            onClick={() => {
                                if (bucketReportForm) {
                                    history.replace(
                                        `/bucket-tool/client/${client.id}`
                                    )
                                    location.reload()
                                } else {
                                    setBucketReportForm(true)
                                }
                            }}
                        >
                            Start a new report
                        </Button>
                    </Col>
                    <Col span={6}></Col>
                </Row>
                <Formik
                    enableReinitialize
                    validationSchema={
                        bucketApproach === BucketApproachEnum.income_goal
                            ? incomeGoalApproachValidationSchema
                            : availableFundsValidationSchema
                    }
                    initialValues={
                        bucketReportForm
                            ? {
                                  name: bucketReportForm.name,
                                  approach: bucketReportForm.approach,
                                  goalAmount: bucketReportForm.goalAmount
                                      ? bucketReportForm.goalAmount
                                      : 1000,
                                  inflationPercentage:
                                      bucketReportForm.inflationPercentage
                                          ? bucketReportForm.inflationPercentage
                                          : 0,
                                  availableBalance:
                                      bucketReportForm.availableBalance
                                          ? bucketReportForm.availableBalance
                                          : 0,
                                  customer: clientId,
                              }
                            : {
                                  name: undefined,
                                  approach: undefined,
                                  goalAmount: 1000,
                                  inflationPercentage: 0,
                                  availableBalance: 0,
                                  customer: clientId,
                              }
                    }
                    onSubmit={(values, actions) => {
                        let annuities: any[] = []
                        let iuls: any[] = []
                        let longTermCares: any[] = []
                        let vaults: any[] = []
                        segments.forEach((segment) => {
                            if (segment.type === SegmentType.annuity) {
                                annuities.push(segment)
                            } else if (segment.type === SegmentType.iul) {
                                iuls.push(segment)
                            } else if (
                                segment.type === SegmentType.longTermCare
                            ) {
                                longTermCares.push(segment)
                            } else if (segment.type === SegmentType.vault) {
                                vaults.push(segment)
                            }
                        })

                        if (bucketReportForm.id) {
                            props.requestUpdateBucketReport(
                                bucketReportForm.id,
                                {
                                    ...values,
                                    vaults: vaults,
                                    longTermCares: longTermCares,
                                    iuls: iuls,
                                    iulsToSave: iulsToSave,
                                    iulsToDelete: iulsToDelete,
                                    annuities: annuities,
                                }
                            )
                        } else {
                            props.requestCreateBucketReport({
                                ...values,
                                vaults: vaults,
                                longTermCares: longTermCares,
                                iuls: iuls,
                                iulsToSave: iulsToSave,
                                annuities: annuities,
                            })
                        }
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        dirty,
                        handleSubmit,
                        handleChange,
                        isSubmitting,
                    }) => (
                        <Form>
                            <Row style={{ paddingBottom: 15 }}>
                                <Col
                                    span={3}
                                    style={{ paddingLeft: 15, display: 'flex' }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                            backgroundColor: '#FAFAFA',
                                        }}
                                    >
                                        <Row>
                                            <Col>
                                                <Title level={5}>
                                                    Client Info
                                                </Title>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text>{client.fullName}</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    {client.gender == 1
                                                        ? `Male, ${client.currentAge}`
                                                        : `Female, ${client.currentAge}`}
                                                </Text>
                                            </Col>
                                        </Row>
                                        {client.spouseFullName.length > 1 && (
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Text>
                                                            {
                                                                client.spouseFullName
                                                            }
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text type="secondary">
                                                            {client.spouseGender ==
                                                            1
                                                                ? 'Male'
                                                                : 'Female'}
                                                            ,{client.spouseAge}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        <br />
                                        <Row>
                                            <Col>{client.address}</Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {client.state},{client.postal}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>{client.phone}</Col>
                                        </Row>
                                        <br />

                                        <Row>
                                            <Col>
                                                <Text>Social security</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.socialSecurityMonthlyIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Text>Pension</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.pensionMonthlyIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Text>Employment</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.employmentMonthlyIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Text>Retirement plans</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.qualifiedPlansMonthlyIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Savings & investments
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.savingsInvestmentsMonthlyIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Text>
                                                    Defined benefit or plan
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $
                                                    {
                                                        client.desiredBenefitMonthlyIncome
                                                    }
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Text>Reserves</Text>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Text type="secondary">
                                                    $0.00
                                                </Text>
                                            </Col>
                                        </Row>
                                        <br />
                                        <br />
                                        <Row>
                                            <Col>
                                                <Title level={5}>Reports</Title>
                                            </Col>
                                        </Row>
                                        <br />
                                        {!bucketReports.loading &&
                                            bucketReports.data &&
                                            bucketReports.data.map(
                                                (report: any) => (
                                                    <>
                                                        <Row
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                setBucketReportForm(
                                                                    undefined
                                                                )
                                                                props.requestBucketReportSegments(
                                                                    report.id
                                                                )
                                                                setBucketReportForm(
                                                                    {
                                                                        ...report,
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <Col>
                                                                <Text className="primary-color">
                                                                    {
                                                                        report.name
                                                                    }
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Text type="secondary">
                                                                    {moment(
                                                                        report.createdOn
                                                                    ).format(
                                                                        'MM/DD/YYYY'
                                                                    )}
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                        <br />
                                                    </>
                                                )
                                            )}
                                    </Card>
                                </Col>

                                <Col
                                    span={17}
                                    style={{ paddingLeft: 15, display: 'flex' }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                        }}
                                    >
                                        <Space
                                            direction="vertical"
                                            size="middle"
                                            style={{ display: 'flex' }}
                                        >
                                            <Row>
                                                <Col span={4}>
                                                    <Card
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Space
                                                            direction="vertical"
                                                            size="middle"
                                                            style={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col>
                                                                    <div className="material-textfield">
                                                                        <select
                                                                            name="approach"
                                                                            onChange={(
                                                                                e: any
                                                                            ) => {
                                                                                handleChange(
                                                                                    e
                                                                                )
                                                                                setBucketApproach(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }}
                                                                            value={
                                                                                values.approach
                                                                            }
                                                                            disabled={
                                                                                !bucketReportForm
                                                                            }
                                                                            placeholder=""
                                                                            className="material-textfield-input"
                                                                        >
                                                                            <option value="none">
                                                                                ---
                                                                            </option>
                                                                            <option value="income_goal">
                                                                                Income
                                                                                goal
                                                                            </option>
                                                                            <option value="available_funds">
                                                                                Available
                                                                                funds
                                                                            </option>
                                                                            <option value="input_only">
                                                                                Input
                                                                                only
                                                                            </option>
                                                                        </select>
                                                                        <label
                                                                            style={{
                                                                                fontWeight:
                                                                                    'normal',
                                                                            }}
                                                                        >
                                                                            Approach
                                                                        </label>
                                                                    </div>
                                                                    <div className="profile-form-error">
                                                                        {errors.approach &&
                                                                            touched.approach &&
                                                                            errors.approach}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            {values.approach ==
                                                                BucketApproachEnum.income_goal && (
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="material-textfield">
                                                                                <input
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    name="goalAmount"
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    value={
                                                                                        values.goalAmount
                                                                                    }
                                                                                    className="material-textfield-input"
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            'normal',
                                                                                    }}
                                                                                >
                                                                                    Goal
                                                                                    amount
                                                                                </label>
                                                                            </div>
                                                                            <div className="profile-form-error">
                                                                                {errors.goalAmount &&
                                                                                    touched.goalAmount &&
                                                                                    errors.goalAmount}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="material-textfield">
                                                                                <input
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    name="inflationPercentage"
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                    value={
                                                                                        values.inflationPercentage
                                                                                    }
                                                                                    className="material-textfield-input"
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            'normal',
                                                                                    }}
                                                                                >
                                                                                    Inflation
                                                                                    %
                                                                                </label>
                                                                            </div>
                                                                            <div className="profile-form-error">
                                                                                {errors.inflationPercentage &&
                                                                                    touched.inflationPercentage &&
                                                                                    errors.inflationPercentage}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )}
                                                            {values.approach &&
                                                                values.approach !==
                                                                    BucketApproachEnum.input_only && (
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="material-textfield">
                                                                                <input
                                                                                    placeholder=""
                                                                                    type="text"
                                                                                    name="availableBalance"
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        setEndingBalance(
                                                                                            calculateSummaryEndingBalance(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        )
                                                                                        setAvailableBalance(
                                                                                            parseFloat(
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        )
                                                                                        handleChange(
                                                                                            e
                                                                                        )
                                                                                    }}
                                                                                    value={
                                                                                        values.availableBalance
                                                                                    }
                                                                                    className="material-textfield-input"
                                                                                />
                                                                                <label
                                                                                    style={{
                                                                                        fontWeight:
                                                                                            'normal',
                                                                                    }}
                                                                                >
                                                                                    Available
                                                                                    funds
                                                                                </label>
                                                                            </div>
                                                                            <div className="profile-form-error">
                                                                                {errors.availableBalance &&
                                                                                    touched.availableBalance &&
                                                                                    errors.availableBalance}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            <Row>
                                                                <Col>
                                                                    <Title
                                                                        level={
                                                                            5
                                                                        }
                                                                        disabled={
                                                                            !bucketReportForm
                                                                        }
                                                                    >
                                                                        Add a
                                                                        segment
                                                                    </Title>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            const annuities =
                                                                                segments.find(
                                                                                    (
                                                                                        segment
                                                                                    ) =>
                                                                                        segment.type ===
                                                                                        SegmentType.annuity
                                                                                ) ||
                                                                                []
                                                                            setSegments(
                                                                                [
                                                                                    ...segments,
                                                                                    {
                                                                                        type: SegmentType.annuity,
                                                                                        position:
                                                                                            segments.length,
                                                                                        startYear:
                                                                                            calculateAnnuityStartYear(),
                                                                                        deferralYears: 0,
                                                                                        monthlyIncome:
                                                                                            calculateAnnuityMonthlyIncome(
                                                                                                values.goalAmount,
                                                                                                values.inflationPercentage
                                                                                            ),
                                                                                        incomeYears: 0,
                                                                                        inflationAdjustedMonthlyIncome:
                                                                                            undefined,
                                                                                        premiumBonusPercentage: 0,
                                                                                        rollupPercentage: 5,
                                                                                        rateOfReturnPercentage: 4,
                                                                                        withdrawalRatePercentage: 1,
                                                                                        payoutMethod:
                                                                                            'income_rider',
                                                                                        starting_value: 0,
                                                                                        initial_start_year:
                                                                                            annuities.length >
                                                                                            0
                                                                                                ? annuities[
                                                                                                      annuities.length -
                                                                                                          1
                                                                                                  ]
                                                                                                      .startYear
                                                                                                : 2023,
                                                                                        totalNeeded: 0,
                                                                                        inflationPercentage: 0,
                                                                                        bonusType:
                                                                                            'premium',
                                                                                        bonusPercentage: 0,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            segments.length ==
                                                                                9
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Annuity
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setSegments(
                                                                                [
                                                                                    ...segments,
                                                                                    {
                                                                                        type: SegmentType.longTermCare,
                                                                                        position:
                                                                                            segments.length,
                                                                                        total_premium: 0,
                                                                                        annual_benefit: 0,
                                                                                        death_benefit: 0,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            segments.length ==
                                                                                9
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        LTC
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setSegments(
                                                                                [
                                                                                    ...segments,
                                                                                    {
                                                                                        type: SegmentType.iul,
                                                                                        position:
                                                                                            segments.length,
                                                                                        total_premium: 0,
                                                                                        annual_income: 0,
                                                                                        increase_percentage: 0,
                                                                                        start_year:
                                                                                            undefined,
                                                                                        end_year:
                                                                                            undefined,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            segments.length ==
                                                                                9
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Life
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Button
                                                                        block
                                                                        onClick={() => {
                                                                            setSegments(
                                                                                [
                                                                                    ...segments,
                                                                                    {
                                                                                        type: SegmentType.vault,
                                                                                        position:
                                                                                            segments.length,
                                                                                        startingBalance: 0,
                                                                                        growthRate: 0,
                                                                                    },
                                                                                ]
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            !bucketReportForm ||
                                                                            !values.approach ||
                                                                            segments.length ==
                                                                                9
                                                                        }
                                                                        className="primary-border-color"
                                                                    >
                                                                        Vault
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Space>
                                                    </Card>
                                                </Col>
                                                <Col
                                                    span={20}
                                                    style={{
                                                        backgroundColor:
                                                            '#BDBDBD',
                                                    }}
                                                >
                                                    {bucketReportForm &&
                                                        segments.length > 0 && (
                                                            <Row
                                                                gutter={4}
                                                                wrap={false}
                                                                style={{
                                                                    overflowX:
                                                                        'scroll',
                                                                    overflowY:
                                                                        'hidden',
                                                                    paddingTop: 15,
                                                                    paddingBottom: 15,
                                                                }}
                                                            >
                                                                {segments.map(
                                                                    (
                                                                        segment
                                                                    ) => {
                                                                        if (
                                                                            segment.type ===
                                                                            SegmentType.annuity
                                                                        ) {
                                                                            return (
                                                                                <AnnuityCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleUpdateSegment={
                                                                                        handleUpdateSegment
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    bucketApproach={
                                                                                        values.approach
                                                                                    }
                                                                                    goalAmount={
                                                                                        values.goalAmount
                                                                                    }
                                                                                    inflationPercentage={
                                                                                        values.inflationPercentage
                                                                                    }
                                                                                    availableFunds={
                                                                                        bucketApproach ==
                                                                                        BucketApproachEnum.available_funds
                                                                                            ? calculateAnnuityAvailableFunds()
                                                                                            : undefined
                                                                                    }
                                                                                    manuallyEdited={
                                                                                        manuallyEditedAnnuity
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else if (
                                                                            segment.type ===
                                                                            SegmentType.longTermCare
                                                                        ) {
                                                                            return (
                                                                                <LongTermCareCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleUpdateSegment={
                                                                                        handleUpdateSegment
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else if (
                                                                            segment.type ===
                                                                            SegmentType.iul
                                                                        ) {
                                                                            return (
                                                                                <IULCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleUpdateSegment={
                                                                                        handleUpdateSegment
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                    requestIULFileParse={
                                                                                        props.requestIULFileParse
                                                                                    }
                                                                                />
                                                                            )
                                                                        } else if (
                                                                            segment.type ===
                                                                            SegmentType.vault
                                                                        ) {
                                                                            return (
                                                                                <VaultCard
                                                                                    position={
                                                                                        segment.position
                                                                                    }
                                                                                    initValues={
                                                                                        segment
                                                                                    }
                                                                                    handleUpdateSegment={
                                                                                        handleUpdateSegment
                                                                                    }
                                                                                    handleRemove={
                                                                                        handleRemove
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                    }
                                                                )}
                                                            </Row>
                                                        )}
                                                </Col>
                                            </Row>
                                        </Space>
                                    </Card>
                                </Col>
                                <Col
                                    span={4}
                                    style={{
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        display: 'flex',
                                    }}
                                >
                                    <Card
                                        style={{
                                            width: '100%',
                                            borderStyle: 'solid',
                                            borderWidth: '2px',
                                            backgroundColor: '#FAFAFA',
                                        }}
                                    >
                                        <Space
                                            direction="vertical"
                                            size="middle"
                                            style={{ display: 'flex' }}
                                        >
                                            <Row>
                                                <Col>
                                                    {bucketReportForm ? (
                                                        <>
                                                            <div className="material-textfield">
                                                                <input
                                                                    placeholder=""
                                                                    name="name"
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    value={
                                                                        values.name
                                                                    }
                                                                    className="material-textfield-input"
                                                                />
                                                                <label
                                                                    style={{
                                                                        fontWeight:
                                                                            'normal',
                                                                    }}
                                                                >
                                                                    Report name
                                                                </label>
                                                            </div>
                                                            <div className="profile-form-error">
                                                                {errors.name &&
                                                                    touched.name &&
                                                                    errors.name}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Title
                                                            level={5}
                                                            disabled
                                                        >
                                                            Report name
                                                        </Title>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Space
                                                direction="vertical"
                                                size="middle"
                                                style={{ display: 'flex' }}
                                            >
                                                <Row>
                                                    <Col>
                                                        <Title
                                                            level={5}
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Summary
                                                        </Title>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Total required
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={12}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            {calculateSummaryTotalRequired()}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={14}>
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Ending balance
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={10}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            $
                                                            {endingBalance
                                                                ? endingBalance
                                                                : 0}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={14}>
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            Years of income
                                                        </Text>
                                                    </Col>
                                                    <Col
                                                        span={10}
                                                        style={{
                                                            textAlign: 'end',
                                                        }}
                                                    >
                                                        <Text
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                        >
                                                            {calculateSummaryYearsOfIncome()}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                                {bucketReportForm &&
                                                    segments.length > 0 &&
                                                    segments.map(
                                                        (segment, index) => {
                                                            let totalNeeded = 0
                                                            let incomeAmount = (
                                                                <></>
                                                            )
                                                            if (
                                                                segment.type ===
                                                                SegmentType.annuity
                                                            ) {
                                                                totalNeeded =
                                                                    segment.totalNeeded
                                                            } else if (
                                                                segment.type ===
                                                                    SegmentType.iul ||
                                                                segment.type ===
                                                                    SegmentType.longTermCare
                                                            ) {
                                                                totalNeeded =
                                                                    segment.totalPremium
                                                            } else if (
                                                                segment.type ===
                                                                SegmentType.vault
                                                            ) {
                                                                totalNeeded =
                                                                    segment.startingBalance
                                                            }

                                                            return (
                                                                <Row>
                                                                    <Col>
                                                                        {
                                                                            SegmentType[
                                                                                segment
                                                                                    .type
                                                                            ]
                                                                        }{' '}
                                                                        #{index}{' '}
                                                                        <br />
                                                                        Premium:{' '}
                                                                        {
                                                                            totalNeeded
                                                                        }
                                                                        {
                                                                            incomeAmount
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    )}
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm ||
                                                                !bucketReportForm.id
                                                            }
                                                            className="primary-color"
                                                            onClick={() =>
                                                                downloadPDFv2(
                                                                    bucketReportForm.id
                                                                )
                                                            }
                                                        >
                                                            Preview
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                        >
                                                            Duplicate
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                        >
                                                            Email client report
                                                        </Button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Button
                                                            type="link"
                                                            size="middle"
                                                            disabled={
                                                                !bucketReportForm
                                                            }
                                                            className="primary-color"
                                                            onClick={() => {
                                                                handleSubmit()
                                                            }}
                                                        >
                                                            Save report
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        </Space>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </>
        )
    } else {
        return <Spin size="large" />
    }
}

const mapStateToProps = (state: any) => {
    return {
        client: state.client,
        bucketReports: state.bucketReports,
        bucketReportSegments: state.bucketReportSegments,
        bucketReport: state.bucketReport,
        iulParseFile: state.iulParseFile,
    }
}

export default connect(mapStateToProps, {
    requestClient,
    requestCreateBucketReport,
    requestBucketReports,
    requestBucketReportSegments,
    requestUpdateBucketReport,
    successCreateBucketReport,
    requestIULFileParse,
})(BucketToolPage)

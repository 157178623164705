import {
    BUCKET_REPORT_GET_SEGMENTS_REQUEST,
    BUCKET_REPORT_GET_SEGMENTS_SUCCESS,
    BUCKET_REPORT_GET_SEGMENTS_FAILURE,
} from '../actions'

export default function bucketReportSegments(
    state = { loading: false },
    action
) {
    switch (action.type) {
        case BUCKET_REPORT_GET_SEGMENTS_REQUEST:
            return { loading: true }
        case BUCKET_REPORT_GET_SEGMENTS_SUCCESS:
            return { loading: false, ...action.data }
        case BUCKET_REPORT_GET_SEGMENTS_FAILURE:
            return { loading: false, error: action.error }
        default:
            return state
    }
}

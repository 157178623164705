import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Auth } from '../index.d'
import { useAuth } from './use_auth'

interface PrivateRouteProps {
    children: React.ReactNode
    auth: Auth
    userRole: string
}

const PrivateRoute = (props: PrivateRouteProps) => {
    const { children, userRole } = props
    const auth = useAuth()
    const location = useLocation()

    return auth.loggedIn &&
        (userRole === null || auth.roles.includes(userRole)) ? (
        children
    ) : (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: location.pathname },
            }}
        />
    )
}

export default PrivateRoute

import {
  ILLUSTRATION_GET_REQUEST,
  ILLUSTRATION_GET_SUCCESS,
  ILLUSTRATION_GET_FAILURE,
  ILLUSTRATION_CREATE_REQUEST,
  ILLUSTRATION_CREATE_SUCCESS,
  ILLUSTRATION_CREATE_FAILURE,
  ILLUSTRATION_UPDATE_REQUEST,
  ILLUSTRATION_UPDATE_SUCCESS,
  ILLUSTRATION_UPDATE_FAILURE,
  ILLUSTRATION_FILE_UPLOAD_PARSE_SUCCESS,
  ILLUSTRATION_CLEAR_SUCCESS,
  ILLUSTRATION_CLEAR_PARSE_REQUEST
} from "../actions";

const baseIllustration = {
  data: {
    id: undefined,
    customer: undefined,
    proposalDate: undefined,
    qualified: false,
    startYear: 2018,
    startAge: 60,
    incomeAmount: 10000,
    incomeYearStart: 70,
    premium: 0,
    payYears: 0,
    ml: false,
    taxRate: 30,
    marketIndex: undefined,
    importData: undefined,
    lines: undefined,
    loanRate: 5,
    addlY1Premium: 0,
    initDeathBenefit: 0,
    modifiedOn: undefined
  }
};

export default function illustration(state = { loading: true, ...baseIllustration }, action) {
  // alert(action.type);
  switch (action.type) {
    case ILLUSTRATION_CREATE_REQUEST:
    case ILLUSTRATION_UPDATE_REQUEST:
    case ILLUSTRATION_GET_REQUEST:
      return { ...state, loading: true };
    case ILLUSTRATION_CREATE_SUCCESS:
    case ILLUSTRATION_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case ILLUSTRATION_GET_SUCCESS:
      return { ...state, loading: false, ...action.data };
    case ILLUSTRATION_CREATE_FAILURE:
    case ILLUSTRATION_UPDATE_FAILURE:
    case ILLUSTRATION_GET_FAILURE:
      return { ...state, loading: false, error: action.error };
    case ILLUSTRATION_CLEAR_SUCCESS:
      return { loading: false, ...baseIllustration };
    case ILLUSTRATION_CLEAR_PARSE_REQUEST:
      return { loading: false, data: { ...state.data, importData: [] } };
    case ILLUSTRATION_FILE_UPLOAD_PARSE_SUCCESS:
      return { loading: false, data: { ...state.data, importData: action.data } };
    default:
      return state;
  }
}

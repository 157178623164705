import * as api from './api'
import { isEmpty } from 'lodash'

export const proposalService = {
    getAllProposals,
    getClientProposals,
    getProposal,
    getDetailProposal,
    createProposal,
    addProposal,
    updateProposal,
    sendProposalLinkByEmail,
    getProposalLinks,
    getProposalLinkViews,
    getProposalLinkEmails,
}

function getClientProposals(client, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/clients/${client}/proposals/`, {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAllProposals({ page, perPage, sorted }) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get('/api/v1/proposals/', {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getProposal({ proposalId }) {
    return api
        .get(`/api/v1/proposals/${proposalId}`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getDetailProposal({ proposalId }) {
    return api
        .get(`/api/v1/proposals/${proposalId}/detail/`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function createProposal(payload) {
    return api
        .post('/api/v1/proposals/new/', payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function addProposal(payload) {
    return api
        .post('/api/v1/proposals/', payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function updateProposal(payload) {
    return api
        .put(`/api/v1/proposals/${payload.proposalId}/`, payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function sendProposalLinkByEmail({ proposalId, email }) {
    return api
        .post(`/api/v1/proposals/${proposalId}/link/`, email)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getProposalLinkEmails() {
    return api
        .get(`/api/v1/proposals/link/emails/`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

export function updateAltRetInvRows(proposalId, values) {
    return api.post(`/api/v1/proposals/${proposalId}/investment/`, values)
}

function getProposalLinks(params) {
    delete params['type']

    return api
        .get('/api/v1/proposal/links/', {
            params: params,
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getProposalLinkViews(params) {
    delete params['type']

    return api
        .get('/api/v1/proposal/links/views/', {
            params: params,
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

export function downloadProposalLinkViews(params) {
    delete params['type']

    return api
        .get('/api/v1/proposal/links/download/', {
            params: params,
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

import { Action } from 'redux'
import {
    AriaV2Payload,
    AuthSuccessPayload,
    BucketType,
    Client,
    CustomerModel,
    IncomeCompModelData,
    PageParams,
    Payloads,
    TaxCalcData,
} from '..'

const action = (actionType: string, payload = {}) => {
    console.log({ type: actionType, ...payload })
    return { type: actionType, ...payload }
}

/****************** UTIL **********************/
export const PROCESS_ERROR = 'PROCESS_ERROR'
export const STATES_GET_REQUEST = 'STATES_GET_REQUEST'
export const STATES_GET_SUCCESS = 'STATES_GET_SUCCESS'
export const STATES_GET_FAILURE = 'STATES_GET_FAILURE'
export const INDEXES_GET_REQUEST = 'INDEXES_GET_REQUEST'
export const INDEXES_GET_SUCCESS = 'INDEXES_GET_SUCCESS'
export const INDEXES_GET_FAILURE = 'INDEXES_GET_FAILURE'
export const ARBITRAGE_GET_REQUEST = 'ARBITRAGE_GET_REQUEST'
export const ARBITRAGE_GET_SUCCESS = 'ARBITRAGE_GET_SUCCESS'
export const ARBITRAGE_GET_FAILURE = 'ARBITRAGE_GET_FAILURE'
export const processErrorMessage = (errorMessage: string) =>
    action(PROCESS_ERROR, { errorMessage })
export const requestStates = () => action(STATES_GET_REQUEST)
export const successStates = (payload: Payloads) =>
    action(STATES_GET_SUCCESS, { data: payload.data })
export const failedStates = (error: string) =>
    action(STATES_GET_FAILURE, { error })
export const requestIndexes = () => action(INDEXES_GET_REQUEST)
export const successIndexes = (payload: Payloads) =>
    action(INDEXES_GET_SUCCESS, { data: payload.data })
export const failedIndexes = (error: string) =>
    action(INDEXES_GET_FAILURE, { error })
export const requestArbitrage = () => action(ARBITRAGE_GET_REQUEST)
export const successArbitrage = (payload: Payloads) =>
    action(ARBITRAGE_GET_SUCCESS, { data: payload.data })
export const failedArbitrage = (error: string) =>
    action(ARBITRAGE_GET_FAILURE, { error })
/****************** AUTH **********************/
export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT = 'LOGOUT'

export const authRequest = (username: string, password: string) =>
    action(AUTH_REQUEST, {
        username,
        password,
    })

export const authSuccess = (response: AuthSuccessPayload) =>
    action(AUTH_SUCCESS, {
        response,
    })

export const authFailure = (error: string) =>
    action(AUTH_FAILURE, {
        error,
    })

export const authLogout = () => action(LOGOUT_REQUEST)

/**************** ARIA_V2S ********************** */
export const ARIA_V2_GET_REQUEST = 'ARIA_V2_GET_REQUEST'
export const ARIA_V2_GET_SUCCESS = 'ARIA_V2_GET_SUCCESS'
export const ARIA_V2_GET_FAILURE = 'ARIA_V2_GET_FAILURE'

export const ARIA_V2_UPDATE_STATE = 'ARIA_V2_UPDATE_STATE'

export const ARIA_V2_CREATE_REQUEST = 'ARIA_V2_CREATE_REQUEST'
export const ARIA_V2_CREATE_SUCCESS = 'ARIA_V2_CREATE_SUCCESS'
export const ARIA_V2_CREATE_FAILURE = 'ARIA_V2_CREATE_FAILURE'

export const ARIA_V2S_GET_REQUEST = 'ARIA_V2S_GET_REQUEST'
export const ARIA_V2S_GET_SUCCESS = 'ARIA_V2S_GET_SUCCESS'
export const ARIA_V2S_GET_FAILURE = 'ARIA_V2S_GET_FAILURE'

export const CLIENT_ARIA_V2S_GET_REQUEST = 'CLIENT_ARIA_V2S_GET_REQUEST'
export const CLIENT_ARIA_V2S_GET_SUCCESS = 'CLIENT_ARIA_V2S_GET_SUCCESS'
export const CLIENT_ARIA_V2S_GET_FAILURE = 'CLIENT_ARIA_V2S_GET_FAILURE'

export const ARIA_V2_ADD_REQUEST = 'ARIA_V2_ADD_REQUEST'
export const ARIA_V2_ADD_SUCCESS = 'ARIA_V2_ADD_SUCCESS'
export const ARIA_V2_ADD_FAILURE = 'ARIA_V2_ADD_FAILURE'

export const ARIA_V2_UPDATE_REQUEST = 'ARIA_V2_UPDATE_REQUEST'
export const ARIA_V2_UPDATE_SUCCESS = 'ARIA_V2_UPDATE_SUCCESS'
export const ARIA_V2_UPDATE_FAILURE = 'ARIA_V2_UPDATE_FAILURE'

export const ARIA_V2_PDFS_GET_REQUEST = 'ARIA_V2_PDFS_GET_REQUEST'
export const ARIA_V2_PDFS_GET_FAILURE = 'ARIA_V2_PDFS_GET_FAILURE'
export const ARIA_V2_PDFS_GET_SUCCESS = 'ARIA_V2_PDFS_GET_SUCCESS'

export const ARIA_V2_RESET_REQUEST = 'ARIA_V2_RESET_REQUEST'
export const ARIA_V2_RESET_SUCCESS = 'ARIA_V2_RESET_SUCCESS'

export const ARIA_V2_FILE_UPLOAD_PARSE_REQUEST =
    'ARIA_V2_FILE_UPLOAD_PARSE_REQUEST'
export const ARIA_V2_FILE_UPLOAD_PARSE_SUCCESS =
    'ARIA_V2_FILE_UPLOAD_PARSE_SUCCESS'
export const ARIA_V2_FILE_UPLOAD_PARSE_FAILURE =
    'ARIA_V2_FILE_UPLOAD_PARSE_FAILURE'

export const requestAriaV2s = (page: number, perPage: number, sorted: string) =>
    action(ARIA_V2S_GET_REQUEST, { page, perPage, sorted })
export const failedAriaV2s = (error: string) =>
    action(ARIA_V2S_GET_FAILURE, { error })
export const successAriaV2s = (response: Payloads) =>
    action(ARIA_V2S_GET_SUCCESS, response)

export const requestAriaV2 = (proposalId: number) =>
    action(ARIA_V2_GET_REQUEST, { proposalId })
export const failedAriaV2 = (error: string) =>
    action(ARIA_V2_GET_FAILURE, { error })
export const successAriaV2 = (response: Payloads) =>
    action(ARIA_V2_GET_SUCCESS, response)

export const requestCreateAriaV2 = (client: Client) =>
    action(ARIA_V2_CREATE_REQUEST, { client })
export const failedCreateAriaV2 = (error: string) =>
    action(ARIA_V2_CREATE_FAILURE, { error })
export const successCreateAriaV2 = (response: AriaV2Payload) =>
    action(ARIA_V2_CREATE_SUCCESS, response)

export const requestResetAriaV2 = (values: any) =>
    action(ARIA_V2_RESET_REQUEST, { values })
export const successResetAriaV2 = (payload: any) =>
    action(ARIA_V2_RESET_SUCCESS, payload)

export const requestAddAriaV2 = (payload: AriaV2Payload) =>
    action(ARIA_V2_ADD_REQUEST, payload)
export const successAddAriaV2 = (response: AriaV2Payload) =>
    action(ARIA_V2_ADD_SUCCESS, response)
export const failedAddAriaV2 = (error: string) =>
    action(ARIA_V2_ADD_FAILURE, error)

export const requestUpdateAriaV2 = (proposalId: number, payload: Payloads) =>
    action(ARIA_V2_UPDATE_REQUEST, { proposalId, ...payload })
export const successUpdateAriaV2 = (response: Payloads) =>
    action(ARIA_V2_UPDATE_SUCCESS, response)
export const failedUpdateAriaV2 = (error: string, data: Payloads) =>
    action(ARIA_V2_UPDATE_FAILURE, { error, data })

export const requestClientAriaV2s = (
    clientId: number,
    page: number,
    perPage: number,
    sorted: string
) =>
    action(CLIENT_ARIA_V2S_GET_REQUEST, {
        clientId,
        page,
        perPage,
        sorted,
    })
export const failedClientAriaV2s = (error: string) =>
    action(CLIENT_ARIA_V2S_GET_FAILURE, { error })
export const successClientAriaV2s = (response: Payloads) =>
    action(CLIENT_ARIA_V2S_GET_SUCCESS, response)

export const requestAriaV2PDFs = () => action(ARIA_V2_PDFS_GET_REQUEST)
export const failedAriaV2PDFs = (error: string) =>
    action(ARIA_V2_PDFS_GET_FAILURE, { error })
export const successAriaV2PDFs = (response: Payloads) =>
    action(ARIA_V2_PDFS_GET_SUCCESS, response)

export const requestAriaV2FileParse = (payload: Payloads, values: any) =>
    action(ARIA_V2_FILE_UPLOAD_PARSE_REQUEST, { payload: payload, values })
export const successAriaV2FileParse = (response: Payloads) =>
    action(ARIA_V2_FILE_UPLOAD_PARSE_SUCCESS, response)
export const failedAriaV2FileParse = (errorMessage: string) =>
    action(ARIA_V2_FILE_UPLOAD_PARSE_FAILURE, { errorMessage })

export const ARIA_V2_STATIC_PDFS_GET_REQUEST = 'ARIA_V2_STATIC_PDFS_GET_REQUEST'
export const ARIA_V2_STATIC_PDFS_GET_FAILURE = 'ARIA_V2_STATIC_PDFS_GET_FAILURE'
export const ARIA_V2_STATIC_PDFS_GET_SUCCESS = 'ARIA_V2_STATIC_PDFS_GET_SUCCESS'

export const requestAriaV2StaticPDFs = () =>
    action(ARIA_V2_STATIC_PDFS_GET_REQUEST)
export const failedAriaV2StaticPDFs = (error: string) =>
    action(ARIA_V2_STATIC_PDFS_GET_FAILURE, { error })
export const successAriaV2StaticPDFs = (response: Payloads) =>
    action(ARIA_V2_STATIC_PDFS_GET_SUCCESS, response)

/**************** PROPOSALS ********************** */
export const PROPOSAL_GET_REQUEST = 'PROPOSAL_GET_REQUEST'
export const PROPOSAL_GET_SUCCESS = 'PROPOSAL_GET_SUCCESS'
export const PROPOSAL_GET_FAILURE = 'PROPOSAL_GET_FAILURE'

export const PROPOSAL_DETAIL_REQUEST = 'PROPOSAL_DETAIL_REQUEST'
export const PROPOSAL_DETAIL_SUCCESS = 'PROPOSAL_DETAIL_SUCCESS'
export const PROPOSAL_DETAIL_FAILURE = 'PROPOSAL_DETAIL_FAILURE'

export const PROPOSAL_UPDATE_STATE = 'PROPOSAL_UPDATE_STATE'

export const PROPOSAL_CREATE_REQUEST = 'PROPOSAL_CREATE_REQUEST'
export const PROPOSAL_CREATE_SUCCESS = 'PROPOSAL_CREATE_SUCCESS'
export const PROPOSAL_CREATE_FAILURE = 'PROPOSAL_CREATE_FAILURE'

export const PROPOSALS_GET_REQUEST = 'PROPOSALS_GET_REQUEST'
export const PROPOSALS_GET_SUCCESS = 'PROPOSALS_GET_SUCCESS'
export const PROPOSALS_GET_FAILURE = 'PROPOSALS_GET_FAILURE'

export const CLIENT_PROPOSALS_GET_REQUEST = 'CLIENT_PROPOSALS_GET_REQUEST'
export const CLIENT_PROPOSALS_GET_SUCCESS = 'CLIENT_PROPOSALS_GET_SUCCESS'
export const CLIENT_PROPOSALS_GET_FAILURE = 'CLIENT_PROPOSALS_GET_FAILURE'

export const PROPOSAL_ADD_REQUEST = 'PROPOSAL_ADD_REQUEST'
export const PROPOSAL_ADD_SUCCESS = 'PROPOSAL_ADD_SUCCESS'
export const PROPOSAL_ADD_FAILURE = 'PROPOSAL_ADD_FAILURE'

export const PROPOSAL_UPDATE_REQUEST = 'PROPOSAL_UPDATE_REQUEST'
export const PROPOSAL_UPDATE_SUCCESS = 'PROPOSAL_UPDATE_SUCCESS'
export const PROPOSAL_UPDATE_FAILURE = 'PROPOSAL_UPDATE_FAILURE'

export const PROPOSAL_LINK_EMAIL_REQUEST = 'PROPOSAL_LINK_EMAIL_REQUEST'

export const PROPOSAL_LINK_EMAILS_REQUEST = 'PROPOSAL_LINK_EMAILS_REQUEST'
export const PROPOSAL_LINK_EMAILS_SUCCESS = 'PROPOSAL_LINK_EMAILS_SUCCESS'
export const PROPOSAL_LINK_EMAILS_FAILURE = 'PROPOSAL_LINK_EMAILS_FAILURE'

export const requestProposalLinkEmails = () =>
    action(PROPOSAL_LINK_EMAILS_REQUEST, {})
export const failedProposalLinkEmails = (error: string) =>
    action(PROPOSAL_LINK_EMAILS_FAILURE, { error })
export const successProposalLinkEmails = (response: Payloads) =>
    action(PROPOSAL_LINK_EMAILS_SUCCESS, response)

export const requestProposalLinkByEmail = (proposalId: number, email: string) =>
    action(PROPOSAL_LINK_EMAIL_REQUEST, { proposalId, email })

export const requestProposals = (
    page: number,
    perPage: number,
    sorted: string
) => action(PROPOSALS_GET_REQUEST, { page, perPage, sorted })
export const failedProposals = (error: string) =>
    action(PROPOSALS_GET_FAILURE, { error })
export const successProposals = (response: Payloads) =>
    action(PROPOSALS_GET_SUCCESS, response)

export const requestProposal = (proposalId: number) =>
    action(PROPOSAL_GET_REQUEST, { proposalId })
export const failedProposal = (error: string) =>
    action(PROPOSAL_GET_FAILURE, { error })
export const successProposal = (response: Payloads) =>
    action(PROPOSAL_GET_SUCCESS, response)

export const requestDetailProposal = (proposalId: number) =>
    action(PROPOSAL_DETAIL_REQUEST, { proposalId })
export const failedDetailProposal = (error: string) =>
    action(PROPOSAL_DETAIL_FAILURE, { error })
export const successDetailProposal = (response: Payloads) =>
    action(PROPOSAL_DETAIL_SUCCESS, response)

export const requestCreateProposal = (illustrationId: number) =>
    action(PROPOSAL_CREATE_REQUEST, { illustrationId })
export const failedCreateProposal = (error: string) =>
    action(PROPOSAL_CREATE_FAILURE, { error })
export const successCreateProposal = (response: Payloads) =>
    action(PROPOSAL_CREATE_SUCCESS, response)

export const requestAddProposal = (illustrationId: number, payload: Payloads) =>
    action(PROPOSAL_ADD_REQUEST, { illustrationId, ...payload })
export const successAddProposal = (response: Payloads) =>
    action(PROPOSAL_ADD_SUCCESS, response)
export const failedAddProposal = (error: string) =>
    action(PROPOSAL_ADD_FAILURE, error)

export const requestUpdateProposal = (proposalId: number, payload: Payloads) =>
    action(PROPOSAL_UPDATE_REQUEST, { proposalId, ...payload })
export const successUpdateProposal = (response: Payloads) =>
    action(PROPOSAL_UPDATE_SUCCESS, response)
export const failedUpdateProposal = (error: string, data: Payloads) =>
    action(PROPOSAL_UPDATE_FAILURE, { error, data })

export const requestClientProposals = (
    clientId: number,
    page: number,
    perPage: number,
    sorted: string
) =>
    action(CLIENT_PROPOSALS_GET_REQUEST, {
        clientId,
        page,
        perPage,
        sorted,
    })
export const failedClientProposals = (error: string) =>
    action(CLIENT_PROPOSALS_GET_FAILURE, { error })
export const successClientProposals = (response: Payloads) =>
    action(CLIENT_PROPOSALS_GET_SUCCESS, response)

/**************** ILLUSTRATIONS ****************** */
export const ILLUSTRATIONS_GET_REQUEST = 'ILLUSTRATIONS_GET_REQUEST'
export const ILLUSTRATIONS_GET_FAILURE = 'ILLUSTRATIONS_GET_FAILURE'
export const ILLUSTRATIONS_GET_SUCCESS = 'ILLUSTRATIONS_GET_SUCCESS'

export const PROPOSAL_ILLUSTRATIONS_GET_REQUEST =
    'PROPOSAL_ILLUSTRATIONS_GET_REQUEST'
export const PROPOSAL_ILLUSTRATIONS_GET_FAILURE =
    'PROPOSAL_ILLUSTRATIONS_GET_FAILURE'
export const PROPOSAL_ILLUSTRATIONS_GET_SUCCESS =
    'PROPOSAL_ILLUSTRATIONS_GET_SUCCESS'

export const ILLUSTRATION_GET_REQUEST = 'ILLUSTRATION_GET_REQUEST'
export const ILLUSTRATION_GET_FAILURE = 'ILLUSTRATION_GET_FAILURE'
export const ILLUSTRATION_GET_SUCCESS = 'ILLUSTRATION_GET_SUCCESS'

export const ILLUSTRATION_CREATE_REQUEST = 'ILLUSTRATION_CREATE_REQUEST'
export const ILLUSTRATION_CREATE_SUCCESS = 'ILLUSTRATION_CREATE_SUCCESS'
export const ILLUSTRATION_CREATE_FAILURE = 'ILLUSTRATION_CREATE_FAILURE'

export const ILLUSTRATION_UPDATE_REQUEST = 'ILLUSTRATION_UPDATE_REQUEST'
export const ILLUSTRATION_UPDATE_SUCCESS = 'ILLUSTRATION_UPDATE_SUCCESS'
export const ILLUSTRATION_UPDATE_FAILURE = 'ILLUSTRATION_UPDATE_FAILURE'

export const ILLUSTRATION_FILE_UPLOAD_PARSE_REQUEST =
    'ILLUSTRATION_FILE_UPLOAD_PARSE_REQUEST'
export const ILLUSTRATION_FILE_UPLOAD_PARSE_SUCCESS =
    'ILLUSTRATION_FILE_UPLOAD_PARSE_SUCCESS'
export const ILLUSTRATION_FILE_UPLOAD_PARSE_FAILURE =
    'ILLUSTRATION_FILE_UPLOAD_PARSE_FAILURE'

export const CLIENT_ILLUSTRATIONS_GET_REQUEST =
    'CLIENT_ILLUSTRATIONS_GET_REQUEST'
export const CLIENT_ILLUSTRATIONS_GET_FAILURE =
    'CLIENT_ILLUSTRATIONS_GET_FAILURE'
export const CLIENT_ILLUSTRATIONS_GET_SUCCESS =
    'CLIENT_ILLUSTRATIONS_GET_SUCCESS'

export const ILLUSTRATION_CLEAR_SUCCESS = 'ILLUSTRATION_CLEAR_SUCCESS'

export const ILLUSTRATION_CLEAR_PARSE_REQUEST =
    'ILLUSTRATION_CLEAR_PARSE_REQUEST'

export const clearIllustration = () =>
    action(ILLUSTRATION_CLEAR_SUCCESS, { data: {} })
export const clearParseIllustration = () =>
    action(ILLUSTRATION_CLEAR_PARSE_REQUEST, { data: {} })

export const requestIllustrations = (
    page: number,
    perPage: number,
    sorted: string
) => action(ILLUSTRATIONS_GET_REQUEST, { page, perPage, sorted })
export const failedIllustrations = (error: string) =>
    action(ILLUSTRATIONS_GET_FAILURE, { error })
export const successIllustrations = (response: Payloads) =>
    action(ILLUSTRATIONS_GET_SUCCESS, response)

export const requestCreateIllustration = (payload: Payloads) => {
    return action(ILLUSTRATION_CREATE_REQUEST, payload)
}
export const successCreateIllustration = (response: Payloads) =>
    action(ILLUSTRATION_CREATE_SUCCESS, response)
export const failedCreateIllustration = (error: string) =>
    action(ILLUSTRATION_CREATE_FAILURE, error)

export const requestFileParse = (payload: Payloads) =>
    action(ILLUSTRATION_FILE_UPLOAD_PARSE_REQUEST, { payload: payload })
export const successFileParse = (response: Payloads) =>
    action(ILLUSTRATION_FILE_UPLOAD_PARSE_SUCCESS, response)
export const failedFileParse = (errorMessage: string) =>
    action(ILLUSTRATION_FILE_UPLOAD_PARSE_FAILURE, { errorMessage })

export const requestUpdateIllustration = (
    illustrationId: number,
    payload: Payloads
) => action(ILLUSTRATION_UPDATE_REQUEST, { illustrationId, payload })
export const successUpdateIllustration = (response: Payloads) =>
    action(ILLUSTRATION_UPDATE_SUCCESS, response)
export const failedUpdateIllustration = (error: string) =>
    action(ILLUSTRATION_UPDATE_FAILURE, error)

export const requestIllustration = (illustrationId: number) =>
    action(ILLUSTRATION_GET_REQUEST, { illustrationId })
export const failedIllustration = (error: string) =>
    action(ILLUSTRATION_GET_FAILURE, { error })
export const successIllustration = (response: Payloads) =>
    action(ILLUSTRATION_GET_SUCCESS, response)

export const requestProposalIllustrations = (
    proposalId: number,
    page: number,
    perPage: number,
    sorted: string
) =>
    action(PROPOSAL_ILLUSTRATIONS_GET_REQUEST, {
        proposalId,
        page,
        perPage,
        sorted,
    })
export const failedProposalIllustrations = (error: string) =>
    action(PROPOSAL_ILLUSTRATIONS_GET_FAILURE, { error })
export const successProposalIllustrations = (response: Payloads) =>
    action(PROPOSAL_ILLUSTRATIONS_GET_SUCCESS, response)

export const requestClientIllustrations = (
    clientId: number,
    page: number,
    perPage: number,
    sorted: string
) =>
    action(CLIENT_ILLUSTRATIONS_GET_REQUEST, {
        clientId,
        page,
        perPage,
        sorted,
    })
export const failedClientIllustrations = (error: string) =>
    action(CLIENT_ILLUSTRATIONS_GET_FAILURE, { error })
export const successClientIllustrations = (response: Payloads) =>
    action(CLIENT_ILLUSTRATIONS_GET_SUCCESS, response)

export const PROPOSAL_LINKS_GET_REQUEST = 'PROPOSAL_LINKS_GET_REQUEST'
export const PROPOSAL_LINKS_GET_FAILURE = 'PROPOSAL_LINKS_GET_FAILURE'
export const PROPOSAL_LINKS_GET_SUCCESS = 'PROPOSAL_LINKS_GET_SUCCESS'

export const requestProposalLinks = (params: PageParams) =>
    action(PROPOSAL_LINKS_GET_REQUEST, params)
export const failedProposalLinks = (error: string) =>
    action(PROPOSAL_LINKS_GET_FAILURE, { error })
export const successProposalLinks = (response: Payloads) =>
    action(PROPOSAL_LINKS_GET_SUCCESS, response)

export const PROPOSAL_LINK_VIEWS_GET_REQUEST = 'PROPOSAL_LINK_VIEWS_GET_REQUEST'
export const PROPOSAL_LINK_VIEWS_GET_FAILURE = 'PROPOSAL_LINK_VIEWS_GET_FAILURE'
export const PROPOSAL_LINK_VIEWS_GET_SUCCESS = 'PROPOSAL_LINK_VIEWS_GET_SUCCESS'

export const requestProposalLinkViews = (params: PageParams) =>
    action(PROPOSAL_LINK_VIEWS_GET_REQUEST, params)
export const failedProposalLinkViews = (error: string) =>
    action(PROPOSAL_LINK_VIEWS_GET_FAILURE, { error })
export const successProposalLinkViews = (response: Payloads) =>
    action(PROPOSAL_LINK_VIEWS_GET_SUCCESS, response)

/**************** CLIENTS ********************** */
export const CLIENT_GET_REQUEST = 'CLIENT_GET_REQUEST'
export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS'
export const CLIENT_GET_FAILURE = 'CLIENT_GET_FAILURE'
export const CLIENT_UPDATE_REQUEST = 'CLIENT_UPDATE_REQUEST'
export const CLIENT_UPDATE_SUCCESS = 'CLIENT_UPDATE_SUCCESS'
export const CLIENT_UPDATE_FAILURE = 'CLIENT_UPDATE_FAILURE'
export const CLIENT_UPDATE_STATE = 'CLIENT_UPDATE_STATE'
export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST'
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS'
export const CLIENT_CREATE_FAILURE = 'CLIENT_CREATE_FAILURE'
export const CLIENTS_GET_REQUEST = 'CLIENTS_GET_REQUEST'
export const CLIENTS_GET_SUCCESS = 'CLIENTS_GET_SUCCESS'
export const CLIENTS_GET_FAILURE = 'CLIENTS_GET_FAILURE'

export const requestClients = (
    page: number,
    perPage: number,
    sorted?: string
): Action => action(CLIENTS_GET_REQUEST, { page, perPage, sorted })
export const requestClient = (clientId: number) =>
    action(CLIENT_GET_REQUEST, { clientId })
export const successClient = (response: Payloads) =>
    action(CLIENT_GET_SUCCESS, { response })
export const failedClient = (error: string) =>
    action(CLIENT_GET_FAILURE, { error })
export const requestCreateClient = (payload: Payloads) =>
    action(CLIENT_CREATE_REQUEST, { payload })
export const successCreateClient = (response: Payloads) =>
    action(CLIENT_CREATE_SUCCESS, { response })
export const failedCreateClient = (error: string) =>
    action(CLIENT_CREATE_FAILURE, { error })
export const requestUpdateClient = (clientId: number, payload: Payloads) =>
    action(CLIENT_UPDATE_REQUEST, { clientId, payload })
export const successUpdateClient = (response: Payloads) =>
    action(CLIENT_UPDATE_SUCCESS, { response })
export const failedUpdatedClient = (error: string) =>
    action(CLIENT_UPDATE_FAILURE, { error })

/**************** CALC CLIENTS ********************** */
export const CALC_CLIENT_GET_REQUEST = 'CALC_CLIENT_GET_REQUEST'
export const CALC_CLIENT_GET_SUCCESS = 'CALC_CLIENT_GET_SUCCESS'
export const CALC_CLIENT_GET_FAILURE = 'CALC_CLIENT_GET_FAILURE'
export const CALC_CLIENT_UPDATE_REQUEST = 'CALC_CLIENT_UPDATE_REQUEST'
export const CALC_CLIENT_UPDATE_SUCCESS = 'CALC_CLIENT_UPDATE_SUCCESS'
export const CALC_CLIENT_UPDATE_FAILURE = 'CALC_CLIENT_UPDATE_FAILURE'
export const CALC_CLIENT_UPDATE_STATE = 'CALC_CLIENT_UPDATE_STATE'
export const CALC_CLIENT_CREATE_REQUEST = 'CALC_CLIENT_CREATE_REQUEST'
export const CALC_CLIENT_CREATE_SUCCESS = 'CALC_CLIENT_CREATE_SUCCESS'
export const CALC_CLIENT_CREATE_FAILURE = 'CALC_CLIENT_CREATE_FAILURE'
export const CALC_CLIENTS_GET_REQUEST = 'CALC_CLIENTS_GET_REQUEST'
export const CALC_CLIENTS_GET_SUCCESS = 'CALC_CLIENTS_GET_SUCCESS'
export const CALC_CLIENTS_GET_FAILURE = 'CALC_CLIENTS_GET_FAILURE'

export const requestCalcClients = (
    page: number,
    perPage: number,
    sorted?: string
): Action => action(CALC_CLIENTS_GET_REQUEST, { page, perPage, sorted })
export const requestCalcClient = (clientId: number) =>
    action(CALC_CLIENT_GET_REQUEST, { clientId })
export const successCalcClient = (response: Payloads) =>
    action(CALC_CLIENT_GET_SUCCESS, { response })
export const failedCalcClient = (error: string) =>
    action(CALC_CLIENT_GET_FAILURE, { error })
export const requestCreateCalcClient = (payload: Payloads) =>
    action(CALC_CLIENT_CREATE_REQUEST, { payload })
export const successCreateCalcClient = (response: Payloads) =>
    action(CALC_CLIENT_CREATE_SUCCESS, { response })
export const failedCreateCalcClient = (error: string) =>
    action(CALC_CLIENT_CREATE_FAILURE, { error })
export const requestUpdateCalcClient = (clientId: number, payload: Payloads) =>
    action(CALC_CLIENT_UPDATE_REQUEST, { clientId, payload })
export const successUpdateCalcClient = (response: Payloads) =>
    action(CALC_CLIENT_UPDATE_SUCCESS, { response })
export const failedUpdatedCalcClient = (error: string) =>
    action(CALC_CLIENT_UPDATE_FAILURE, { error })

/**************** INCOME_COMPS ********************** */
export const INCOME_COMP_GET_REQUEST = 'INCOME_COMP_GET_REQUEST'
export const INCOME_COMP_GET_SUCCESS = 'INCOME_COMP_GET_SUCCESS'
export const INCOME_COMP_GET_FAILURE = 'INCOME_COMP_GET_FAILURE'
export const INCOME_COMP_UPDATE_REQUEST = 'INCOME_COMP_UPDATE_REQUEST'
export const INCOME_COMP_UPDATE_SUCCESS = 'INCOME_COMP_UPDATE_SUCCESS'
export const INCOME_COMP_UPDATE_FAILURE = 'INCOME_COMP_UPDATE_FAILURE'
export const INCOME_COMP_TAX_CALC_UPDATE_REQUEST =
    'INCOME_COMP_TAX_CALC_UPDATE_REQUEST'
export const INCOME_COMP_TAX_CALC_UPDATE_SUCCESS =
    'INCOME_COMP_TAX_CALC_UPDATE_SUCCESS'
export const INCOME_COMP_TAX_CALC_UPDATE_FAILURE =
    'INCOME_COMP_TAX_CALC_UPDATE_FAILURE'
export const INCOME_COMP_UPDATE_STATE = 'INCOME_COMP_UPDATE_STATE'
export const INCOME_COMP_CREATE_REQUEST = 'INCOME_COMP_CREATE_REQUEST'
export const INCOME_COMP_CREATE_SUCCESS = 'INCOME_COMP_CREATE_SUCCESS'
export const INCOME_COMP_CREATE_FAILURE = 'INCOME_COMP_CREATE_FAILURE'
export const INCOME_COMPS_GET_REQUEST = 'INCOME_COMPS_GET_REQUEST'
export const INCOME_COMPS_GET_SUCCESS = 'INCOME_COMPS_GET_SUCCESS'
export const INCOME_COMPS_GET_FAILURE = 'INCOME_COMPS_GET_FAILURE'
export const INCOME_COMP_RESET_REQUEST = 'INCOME_COMP_RESET_REQUEST'
export const INCOME_COMP_RESET_SUCCESS = 'INCOME_COMP_RESET_SUCCESS'
export const INCOME_COMP_SET_CLIENT_REQUEST = 'INCOME_COMP_SET_CLIENT_REQUEST'
export const INCOME_COMP_SET_CLIENT_SUCCESS = 'INCOME_COMP_SET_CLIENT_SUCCESS'

export const requestIncomeComps = (
    current: number,
    perPage: number,
    sorted: string,
    filters: { [key: string]: string }
) =>
    action(INCOME_COMPS_GET_REQUEST, {
        page: current,
        perPage,
        sorted,
        filters,
    })
export const requestIncomeComp = (incomeCompId: number) =>
    action(INCOME_COMP_GET_REQUEST, { incomeCompId })
export const successIncomeComp = (response: IncomeCompModelData[]) =>
    action(INCOME_COMP_GET_SUCCESS, { response })
export const failedIncomeComp = (error: string) =>
    action(INCOME_COMP_GET_FAILURE, { error })
export const requestCreateIncomeComp = (payload: IncomeCompModelData) =>
    action(INCOME_COMP_CREATE_REQUEST, { payload })
export const successCreateIncomeComp = (response: IncomeCompModelData) =>
    action(INCOME_COMP_CREATE_SUCCESS, { response })
export const failedCreateIncomeComp = (error: string) =>
    action(INCOME_COMP_CREATE_FAILURE, { error })
export const requestResetIncomeComp = () => action(INCOME_COMP_RESET_REQUEST)
export const successResetIncomeComp = (payload: IncomeCompModelData) =>
    action(INCOME_COMP_RESET_SUCCESS, { data: payload })
export const requestUpdateIncomeComp = (
    incomeCompId: number,
    payload: IncomeCompModelData
) => action(INCOME_COMP_UPDATE_REQUEST, { incomeCompId, payload })
export const successUpdateIncomeComp = (response: IncomeCompModelData) =>
    action(INCOME_COMP_UPDATE_SUCCESS, response)
export const failedUpdatedIncomeComp = (error: string) =>
    action(INCOME_COMP_UPDATE_FAILURE, { error })
export const requestUpdateIncomeCompTaxCalc = (
    incomeCompId: number,
    payload: TaxCalcData
) => action(INCOME_COMP_TAX_CALC_UPDATE_REQUEST, { incomeCompId, payload })
export const successUpdateIncomeCompTaxCalc = () =>
    action(INCOME_COMP_TAX_CALC_UPDATE_SUCCESS)
export const failedUpdatedIncomeCompTaxCalc = () =>
    action(INCOME_COMP_TAX_CALC_UPDATE_FAILURE)
export const requestSetIncomeCompClient = (customer: CustomerModel) =>
    action(INCOME_COMP_SET_CLIENT_REQUEST, { customer })
export const successSetIncomeCompClient = (customer: CustomerModel) =>
    action(INCOME_COMP_SET_CLIENT_SUCCESS, { data: customer })

/***************** AGENCIES **************************/
export const AGENCY_GET_REQUEST = 'AGENCY_GET_REQUEST'
export const AGENCY_GET_SUCCESS = 'AGENCY_GET_SUCCESS'
export const AGENCY_GET_FAILURE = 'AGENCY_GET_FAILURE'
export const AGENCY_CREATE_REQUEST = 'AGENCY_CREATE_REQUEST'
export const AGENCY_CREATE_SUCCESS = 'AGENCY_CREATE_SUCCESS'
export const AGENCY_CREATE_FAILURE = 'AGENCY_CREATE_FAILURE'
export const AGENCY_AGENT_CREATE_REQUEST = 'AGENCY_AGENT_CREATE_REQUEST'
export const AGENCY_AGENT_CREATE_SUCCESS = 'AGENCY_AGENT_CREATE_SUCCESS'
export const AGENCY_AGENT_CREATE_FAILURE = 'AGENCY_AGENT_CREATE_FAILURE'
export const AGENCY_UPDATE_REQUEST = 'AGENCY_UPDATE_REQUEST'
export const AGENCY_UPDATE_SUCCESS = 'AGENCY_UPDATE_SUCCESS'
export const AGENCY_UPDATE_FAILURE = 'AGENCY_UPDATE_FAILURE'
export const AGENCIES_GET_REQUEST = 'AGENCIES_GET_REQUEST'
export const AGENCIES_GET_SUCCESS = 'AGENCIES_GET_SUCCESS'
export const AGENCIES_GET_FAILURE = 'AGENCIES_GET_FAILURE'
export const AGENCY_AGENTS_GET_REQUEST = 'AGENCY_AGENTS_GET_REQUEST'
export const AGENCY_AGENTS_GET_SUCCESS = 'AGENCY_AGENTS_GET_SUCCESS'
export const AGENCY_AGENTS_GET_FAILURE = 'AGENCY_AGENTS_GET_FAILURE'
export const AGENCIES_AGENTS_GET_REQUEST = 'AGENCIES_AGENTS_GET_REQUEST'
export const AGENCIES_AGENTS_GET_SUCCESS = 'AGENCIES_AGENTS_GET_SUCCESS'
export const AGENCIES_AGENTS_GET_FAILURE = 'AGENCIES_AGENTS_GET_FAILURE'

export const requestAgencies = (
    page: number,
    perPage: number,
    sorted: string
) =>
    action(AGENCIES_GET_REQUEST, {
        page,
        perPage,
        sorted,
    })
export const successAgencies = (response: Payloads) =>
    action(AGENCIES_GET_SUCCESS, response)
export const failedAgencies = (error: string) =>
    action(AGENCIES_GET_FAILURE, error)
export const requestAgency = (agencyId: number) =>
    action(AGENCY_GET_REQUEST, { agencyId })
export const successAgency = (response: Payloads) =>
    action(AGENCY_GET_SUCCESS, response)
export const failedAgency = (error: string) => action(AGENCY_GET_FAILURE, error)
export const requestCreateAgency = (payload: Payloads) =>
    action(AGENCY_CREATE_REQUEST, { payload })
export const successCreateAgency = (response: Payloads) =>
    action(AGENCY_CREATE_SUCCESS, { response })
export const failedCreateAgency = (error: string) =>
    action(AGENCY_CREATE_FAILURE, error)
export const requestUpdateAgency = (agencyId: number, payload: Payloads) =>
    action(AGENCY_UPDATE_REQUEST, { agencyId, payload })
export const successUpdateAgency = (response: Payloads) =>
    action(AGENCY_UPDATE_SUCCESS, { response })
export const failedUpdateAgency = (error: string) =>
    action(AGENCY_UPDATE_FAILURE, error)
export const requestAgencyAgents = (
    agencyId: number,
    page: number,
    perPage: number,
    sorted: string
) => action(AGENCY_AGENTS_GET_REQUEST, { agencyId, page, perPage, sorted })
export const successAgencyAgents = (response: Payloads) =>
    action(AGENCY_AGENTS_GET_SUCCESS, response)
export const failedAgencyAgents = (error: string) =>
    action(AGENCY_AGENTS_GET_FAILURE, error)
export const requestAgenciesAgents = () => action(AGENCIES_AGENTS_GET_REQUEST)
export const successAgenciesAgents = (response: Payloads) =>
    action(AGENCIES_AGENTS_GET_SUCCESS, response)
export const failedAgenciesAgents = (error: string) =>
    action(AGENCIES_AGENTS_GET_FAILURE, error)
export const requestCreateAgencyAgent = (agencyId: number, payload: Payloads) =>
    action(AGENCY_AGENT_CREATE_REQUEST, { agencyId, payload })
export const successCreateAgencyAgent = (response: Payloads) =>
    action(AGENCY_AGENT_CREATE_SUCCESS, { response })
export const failedCreateAgencyAgent = (error: string) =>
    action(AGENCY_AGENT_CREATE_FAILURE, error)

/***************** AGENTS **************************/
export const AGENT_GET_REQUEST = 'AGENT_GET_REQUEST'
export const AGENT_GET_SUCCESS = 'AGENT_GET_SUCCESS'
export const AGENT_GET_FAILURE = 'AGENT_GET_FAILURE'
export const AGENT_CLIENTS_GET_REQUEST = 'AGENT_CLIENTS_GET_REQUEST'
export const AGENT_CLIENTS_GET_SUCCESS = 'AGENT_CLIENTS_GET_SUCCESS'
export const AGENT_CLIENTS_GET_FAILURE = 'AGENT_CLIENTS_GET_FAILURE'
export const AGENT_PROPOSALS_GET_REQUEST = 'AGENT_PROPOSALS_GET_REQUEST'
export const AGENT_PROPOSALS_GET_SUCCESS = 'AGENT_PROPOSALS_GET_SUCCESS'
export const AGENT_PROPOSALS_GET_FAILURE = 'AGENT_PROPOSALS_GET_FAILURE'
export const AGENT_ARIA_V2S_GET_REQUEST = 'AGENT_ARIA_V2S_GET_REQUEST'
export const AGENT_ARIA_V2S_GET_SUCCESS = 'AGENT_ARIA_V2S_GET_SUCCESS'
export const AGENT_ARIA_V2S_GET_FAILURE = 'AGENT_ARIA_V2S_GET_FAILURE'
export const AGENT_CREATE_REQUEST = 'AGENT_CREATE_REQUEST'
export const AGENT_CREATE_SUCCESS = 'AGENT_CREATE_SUCCESS'
export const AGENT_CREATE_FAILURE = 'AGENT_CREATE_FAILURE'
export const AGENT_UPDATE_REQUEST = 'AGENT_UPDATE_REQUEST'
export const AGENT_UPDATE_SUCCESS = 'AGENT_UPDATE_SUCCESS'
export const AGENT_UPDATE_FAILURE = 'AGENT_UPDATE_FAILURE'
export const AGENTS_GET_REQUEST = 'AGENTS_GET_REQUEST'
export const AGENTS_GET_SUCCESS = 'AGENTS_GET_SUCCESS'
export const AGENTS_GET_FAILURE = 'AGENTS_GET_FAILURE'

export const requestAgent = (agencyId: number) =>
    action(AGENT_GET_REQUEST, { agencyId })
export const successAgent = (response: Payloads) =>
    action(AGENT_GET_SUCCESS, response)
export const failedAgent = (error: string) => action(AGENT_GET_FAILURE, error)
export const requestAgentClients = (
    agentId: number,
    page: number,
    perPage: number,
    sorted: string
) => action(AGENT_CLIENTS_GET_REQUEST, { agentId, page, perPage, sorted })
export const successAgentClients = (response: Payloads) =>
    action(AGENT_CLIENTS_GET_SUCCESS, response)
export const failedAgentClients = (error: string) =>
    action(AGENT_CLIENTS_GET_FAILURE, error)
export const requestAgentProposals = (
    agentId: number,
    page: number,
    perPage: number,
    sorted: string
) => action(AGENT_PROPOSALS_GET_REQUEST, { agentId, page, perPage, sorted })
export const successAgentProposals = (response: Payloads) =>
    action(AGENT_PROPOSALS_GET_SUCCESS, response)
export const failedAgentProposals = (error: string) =>
    action(AGENT_PROPOSALS_GET_FAILURE, error)
export const requestAgentAriaV2s = (
    agentId: number,
    page: number,
    perPage: number,
    sorted: string
) => action(AGENT_ARIA_V2S_GET_REQUEST, { agentId, page, perPage, sorted })
export const successAgentAriaV2s = (response: Payloads) =>
    action(AGENT_ARIA_V2S_GET_SUCCESS, response)
export const failedAgentAriaV2s = (error: string) =>
    action(AGENT_ARIA_V2S_GET_FAILURE, error)
export const requestUpdateAgent = (agentId: number, payload: Payloads) =>
    action(AGENT_UPDATE_REQUEST, { agentId, payload })
export const successUpdateAgent = (response: Payloads) =>
    action(AGENT_UPDATE_SUCCESS, response)
export const failedUpdateAgent = (error: string) =>
    action(AGENT_UPDATE_FAILURE, error)
export const requestCreateAgent = (payload: Payloads) =>
    action(AGENT_CREATE_REQUEST, payload)
export const successCreateAgent = (response: Payloads) =>
    action(AGENT_CREATE_SUCCESS, response)
export const failedCreateAgent = (error: string) =>
    action(AGENT_CREATE_FAILURE, error)
export const requestAgents = () => action(AGENTS_GET_REQUEST)
export const successAgents = (response: Payloads) =>
    action(AGENTS_GET_SUCCESS, response)
export const failedAgents = (error: string) => action(AGENTS_GET_FAILURE, error)

/***************** ALERTS **************************/
export const ALERT_SUCCESS = 'ALERT_SUCCESS'
export const ALERT_ERROR = 'ALERT_ERROR'
export const ALERT_CLEAR = 'ALERT_CLEAR'

export const successAlert = (message: string) =>
    action(ALERT_SUCCESS, { alert: 'success', message })
export const errorAlert = (message: string) =>
    action(ALERT_ERROR, { alert: 'error', message })
export const clearAlert = () => action(ALERT_CLEAR)

/***************** BUCKET REPORTS **************************/
export const BUCKET_REPORT_GET_REQUEST = 'BUCKET_REPORT_GET_REQUEST'
export const BUCKET_REPORT_GET_SUCCESS = 'BUCKET_REPORT_GET_SUCCESS'
export const BUCKET_REPORT_GET_FAILURE = 'BUCKET_REPORT_GET_FAILURE'
export const BUCKET_REPORT_CREATE_REQUEST = 'BUCKET_REPORT_CREATE_REQUEST'
export const BUCKET_REPORT_CREATE_SUCCESS = 'BUCKET_REPORT_CREATE_SUCCESS'
export const BUCKET_REPORT_CREATE_FAILURE = 'BUCKET_REPORT_CREATE_FAILURE'
export const BUCKET_REPORT_UPDATE_REQUEST = 'BUCKET_REPORT_UPDATE_REQUEST'
export const BUCKET_REPORT_UPDATE_SUCCESS = 'BUCKET_REPORT_UPDATE_SUCCESS'
export const BUCKET_REPORT_UPDATE_FAILURE = 'BUCKET_REPORT_UPDATE_FAILURE'
export const BUCKET_REPORTS_GET_REQUEST = 'BUCKET_REPORTS_GET_REQUEST'
export const BUCKET_REPORTS_GET_SUCCESS = 'BUCKET_REPORTS_GET_SUCCESS'
export const BUCKET_REPORTS_GET_FAILURE = 'BUCKET_REPORTS_GET_FAILURE'
export const BUCKET_REPORT_GET_SEGMENTS_REQUEST =
    'BUCKET_REPORT_GET_SEGMENTS_REQUEST'
export const BUCKET_REPORT_GET_SEGMENTS_SUCCESS =
    'BUCKET_REPORT_GET_SEGMENTS_SUCCESS'
export const BUCKET_REPORT_GET_SEGMENTS_FAILURE =
    'BUCKET_REPORT_GET_SEGMENTS_FAILURE'

export const requestBucketReports = (clientId: number) =>
    action(BUCKET_REPORTS_GET_REQUEST, {
        clientId,
    })
export const successBucketReports = (response: Payloads) =>
    action(BUCKET_REPORTS_GET_SUCCESS, response)
export const failedBucketReports = (error: string) =>
    action(BUCKET_REPORTS_GET_FAILURE, error)
export const requestBucketReport = (bucketReportId: number) =>
    action(BUCKET_REPORT_GET_REQUEST, { bucketReportId })
export const successBucketReport = (response: Payloads) =>
    action(BUCKET_REPORT_GET_SUCCESS, response)
export const failedBucketReport = (error: string) =>
    action(BUCKET_REPORT_GET_FAILURE, error)
export const requestCreateBucketReport = (payload: Payloads) =>
    action(BUCKET_REPORT_CREATE_REQUEST, { payload })
export const successCreateBucketReport = (response: Payloads) =>
    action(BUCKET_REPORT_CREATE_SUCCESS, { response })
export const failedCreateBucketReport = (error: string) =>
    action(BUCKET_REPORT_CREATE_FAILURE, error)
export const requestUpdateBucketReport = (
    bucketReportId: number,
    payload: Payloads
) => action(BUCKET_REPORT_UPDATE_REQUEST, { bucketReportId, payload })
export const successUpdateBucketReport = (response: Payloads) =>
    action(BUCKET_REPORT_UPDATE_SUCCESS, { response })
export const failedUpdateBucketReport = (error: string) =>
    action(BUCKET_REPORT_UPDATE_FAILURE, error)
export const requestBucketReportSegments = (reportId: number) =>
    action(BUCKET_REPORT_GET_SEGMENTS_REQUEST, {
        reportId,
    })
export const successBucketReportSegments = (response: Payloads) =>
    action(BUCKET_REPORT_GET_SEGMENTS_SUCCESS, response)
export const failedBucketReportSegments = (error: string) =>
    action(BUCKET_REPORT_GET_SEGMENTS_FAILURE, error)

export const IUL_FILE_UPLOAD_PARSE_REQUEST = 'IUL_FILE_UPLOAD_PARSE_REQUEST'
export const IUL_FILE_UPLOAD_PARSE_SUCCESS = 'IUL_FILE_UPLOAD_PARSE_SUCCESS'
export const IUL_FILE_UPLOAD_PARSE_FAILURE = 'IUL_FILE_UPLOAD_PARSE_FAILURE'

export const requestIULFileParse = (payload: Payloads, position: any) =>
    action(IUL_FILE_UPLOAD_PARSE_REQUEST, {
        payload: payload,
        position: position,
    })
export const successIULFileParse = (response: Payloads) =>
    action(IUL_FILE_UPLOAD_PARSE_SUCCESS, response)
export const failedIULFileParse = (errorMessage: string) =>
    action(IUL_FILE_UPLOAD_PARSE_FAILURE, { errorMessage })

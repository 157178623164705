import * as api from './api'
import { isEmpty } from 'lodash'

export const illustrationService = {
    getAllIllustrations,
    getIllustration,
    createIllustration,
    updateIllustration,
    uploadParseFile,
    getClientIllustrations,
}

function getAllIllustrations({ page, perPage, sorted }) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get('/api/v1/illustrations/', {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getIllustration({ illustrationId }) {
    return api
        .get(`/api/v1/illustrations/${illustrationId}`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function createIllustration(clientId, importData) {
    return api
        .post('/api/v1/illustrations/', { clientId, importData })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function updateIllustration({ illustrationId, payload }) {
    return api
        .put(`/api/v1/illustrations/${illustrationId}/`, payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

async function uploadParseFile(files) {
    if (files.length === 0) api.handleError('Please select a valid file')
    if (
        files[0].type !== 'text/csv' &&
        files[0].type !== 'application/vnd.ms-excel' &&
        files[0].type !==
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    )
        api.handleError(
            `Can only parse CSV files - file of type: ${files[0].type}`
        )
    const data = new FormData()
    await data.append('file', files[0], files[0].name)
    return api
        .post('/api/v1/illustrations/parse-file/', data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getClientIllustrations(client, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/clients/${client}/illustrations/`, {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

import {
  PROPOSAL_GET_REQUEST,
  PROPOSAL_GET_SUCCESS,
  PROPOSAL_GET_FAILURE,
  PROPOSAL_DETAIL_REQUEST,
  PROPOSAL_DETAIL_SUCCESS,
  PROPOSAL_DETAIL_FAILURE,
  PROPOSAL_UPDATE_STATE,
  PROPOSAL_CREATE_REQUEST,
  PROPOSAL_CREATE_SUCCESS,
  PROPOSAL_CREATE_FAILURE,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_ADD_REQUEST,
  PROPOSAL_UPDATE_FAILURE,
  PROPOSAL_UPDATE_SUCCESS
} from "../actions";

export default function proposal(state = { loading: true }, action) {
  switch (action.type) {
    case PROPOSAL_GET_REQUEST:
    case PROPOSAL_CREATE_REQUEST:
    case PROPOSAL_DETAIL_REQUEST:
    case PROPOSAL_UPDATE_REQUEST:
    case PROPOSAL_ADD_REQUEST:
      return { loading: true };
    case PROPOSAL_GET_SUCCESS:
    case PROPOSAL_CREATE_SUCCESS:
    case PROPOSAL_UPDATE_SUCCESS:
    case PROPOSAL_DETAIL_SUCCESS:
      return { loading: false, ...action.data };
    case PROPOSAL_GET_FAILURE:
    case PROPOSAL_CREATE_FAILURE:
    case PROPOSAL_DETAIL_FAILURE:
    case PROPOSAL_UPDATE_FAILURE:
      return { loading: false, data: action.data };
    case PROPOSAL_UPDATE_STATE:
      return { ...state.proposal, loading: false, ...action.data };
    default:
      return state;
  }
}

import * as api from './api'
import { isEmpty } from 'lodash'

export const agencyService = {
    getAllAgencies,
    getAgencyAgents,
    getAgenciesAgents,
    getAgency,
    createAgency,
    updateAgency,
    addAgencyAgent,
}

function getAgencyAgents(agencyId, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/agencies/${agencyId}/agents/`, {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAllAgencies({ page, perPage, sorted }) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get('/api/v1/agencies/', {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAgenciesAgents() {
    return api
        .get('/api/v1/agencies-agents/')
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function getAgency(agencyId) {
    return api
        .get(`/api/v1/agencies/${agencyId}/`)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function createAgency(payload) {
    return api
        .post('/api/v1/agencies/', payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function updateAgency({ agencyId, payload }) {
    return api
        .put(`/api/v1/agencies/${agencyId}/`, payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

function addAgencyAgent({ agencyId, payload }) {
    return api
        .post(`/api/v1/agencies/${agencyId}/agents/`, payload)
        .then((response) => {
            return response
        })
        .catch(api.handleError)
}

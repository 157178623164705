import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Spin, Tabs } from 'antd'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestAgencyAgents } from '../../../actions'

type TabPosition = 'left' | 'right' | 'top' | 'bottom'

const AdvisorsClientsTabs = (props: any) => {
    const { data, loading, error, selectedAgency, setShowProfile } = props
    useEffect(() => {
        props.requestAgencyAgents(selectedAgency.id, 0, 100)
    }, [])
    const [tabPosition, setTabPosition] = useState<TabPosition>('left')
    const [searchValue, setSearchValue] = useState('')

    if (!loading && data) {
        const itemsMapped = data.map((advisor: any) => {
            return { label: advisor.fullName, key: advisor.id.toString() }
        })
        return (
            <Tabs
                onTabClick={(key, event) => {
                    setShowProfile({
                        for: 'advisor',
                        id: parseInt(key),
                    })
                }}
                tabBarExtraContent={{
                    left: (
                        <Row>
                            <Col span={23}>
                                <Input
                                    placeholder="Refine profiles"
                                    prefix={<SearchOutlined rev={undefined} />}
                                    onChange={(event) => {
                                        setSearchValue(event.target.value)
                                    }}
                                />
                            </Col>
                            <Col span={1}></Col>
                        </Row>
                    ),
                }}
                tabPosition={tabPosition}
                items={itemsMapped}
            />
        )
    } else {
        return <Spin size="large" />
    }
}

const mapStateToProps = (state: any) => {
    return {
        ...state.agents,
    }
}

export default connect(mapStateToProps, { requestAgencyAgents })(
    AdvisorsClientsTabs
)

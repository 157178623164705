import { camelizeKeys } from 'humps'
import jwt_decode from 'jwt-decode'
import * as api from './api'

const storeToken = ({ token, username, roles }) => {
    localStorage.setItem('token', token)
    localStorage.setItem('username', username)
    localStorage.setItem('roles', roles)
}

const clearToken = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('roles')
}

const validateToken = (token) => {
    if (token) {
        const now = Date.now().valueOf() / 1000
        const decoded = jwt_decode(token)
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
            return false
        } else {
            return true
        }
    } else {
        return false
    }
}

const authorize = (credentials) => {
    return api
        .post('/api/v1/auth/', credentials)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.token) {
                    return { status: 'ok', ...camelizeKeys(response.data) }
                } else {
                    return {
                        status: 'ko',
                        message:
                            'Server failed to log you in, please contact support',
                        ...camelizeKeys(response.data),
                    }
                }
            }
        })
        .catch((error) => {
            return { status: 'error', message: 'Invalid credentials', error }
        })
}

const verify = () => {
    return api
        .get('/api/v1/me/')
        .then((response) => {
            if (response.status === 200) {
                return { status: 'ok', ...camelizeKeys(response.data) }
            }
        })
        .catch((error) => {
            return { status: 'error', message: 'Invalid credentials', error }
        })
}

function changePassword(data) {
    return api.post('/api/v1/change-password/', data)
}

const authService = {
    authorize,
    verify,
    validateToken,
    storeToken,
    clearToken,
    changePassword,
}

export default authService

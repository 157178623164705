import {
    ARIA_V2S_GET_REQUEST,
    CLIENT_ARIA_V2S_GET_REQUEST,
    ARIA_V2S_GET_FAILURE,
    CLIENT_ARIA_V2S_GET_FAILURE,
    ARIA_V2S_GET_SUCCESS,
    CLIENT_ARIA_V2S_GET_SUCCESS,
    AGENT_ARIA_V2S_GET_REQUEST,
    AGENT_ARIA_V2S_GET_SUCCESS,
    AGENT_ARIA_V2S_GET_FAILURE,
} from '../actions'

const baseState = {
    loading: true,
    page: 0,
    perPage: 25,
    count: 0,
    pagination: {
        pageSize: 25,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['25', '50', '100'],
    },
    sorted: undefined,
    data: [{}],
    links: [{ createdBy: {} }],
    link_views: [],
    emails: [],
}

export default function ariav2s(state = baseState, action) {
    switch (action.type) {
        case CLIENT_ARIA_V2S_GET_REQUEST:
        case AGENT_ARIA_V2S_GET_REQUEST:
        case ARIA_V2S_GET_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case CLIENT_ARIA_V2S_GET_SUCCESS:
        case AGENT_ARIA_V2S_GET_SUCCESS:
        case ARIA_V2S_GET_SUCCESS:
            return {
                ...action.data,
                loading: false,
            }
        case CLIENT_ARIA_V2S_GET_FAILURE:
        case AGENT_ARIA_V2S_GET_FAILURE:
        case ARIA_V2S_GET_FAILURE:
            return { ...baseState, loading: false, error: action.error }

        default:
            return state
    }
}

import { AGENCIES_GET_REQUEST, AGENCIES_GET_SUCCESS, AGENCIES_GET_FAILURE } from "../actions";

export default function agencies(
  state = { loading: true, perPage: 25, count: 0, page: 0, sorted: undefined, data: [] },
  action
) {
  switch (action.type) {
    case AGENCIES_GET_REQUEST:
      return { loading: true, perPage: 25, count: 0, page: 0, sorted: undefined, data: [] };
    case AGENCIES_GET_SUCCESS:
      return { loading: false, ...action.data };
    case AGENCIES_GET_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

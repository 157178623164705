import * as api from './api'

export const commonService = {
  getIndexes,
  getStates,
  getArbitrage,
  getTaxAmount
};

function getStates() {
  return api
    .get("/api/v1/states/")
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getTaxAmount(taxableAmount, filingStatus, taxYear) {
  return api
    .post("/api/v1/tax-amount/", {taxableAmount, filingStatus, taxYear})
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getIndexes() {
  return api
    .get("/api/v1/indexes/")
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getArbitrage() {
  return api
    .get("/api/v1/arbitrage/")
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}


import * as api from './api'
import { isEmpty } from 'lodash'

export const agentService = {
    getAgencyAgents,
    getAgentProposals,
    getAgentClients,
    getAgent,
    createAgent,
    updateAgent,
    getAgents,
}

function getAgentClients(agentId, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/agents/${agentId}/clients/`, {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function getAgentProposals(agentId, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/agents/${agentId}/proposals/`, {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function getAgencyAgents(agencyId, page, perPage, sorted) {
    if (isEmpty(sorted)) {
        sorted = null
    }
    return api
        .get(`/api/v1/agencies/${agencyId}/agents/`, {
            params: { page: page, per_page: perPage, sorted: sorted },
        })
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function getAgents() {
    return api
        .get(`/api/v1/agents/`)
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function getAgent(agentId) {
    return api
        .get(`/api/v1/agents/${agentId}/`)
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function createAgent(payload) {
    return api
        .post('/api/v1/agents/', payload)
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function updateAgent({ agentId, payload }) {
    return api
        .put(`/api/v1/agents/${agentId}/`, payload)
        .then((response) => {
            return response
        })
        .catch(handleError)
}

function handleError(error) {
    console.error(error)
}

import { SearchOutlined } from '@ant-design/icons'
import { Col, Input, Row, Spin, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { requestAgencyAgents, requestAgentClients } from '../../../actions'
const { Text } = Typography

const ProfileTable = (props: any) => {
    const {
        agents,
        clients,
        loading,
        error,
        states,
        showTableFor,
        setShowProfile,
        setShowProfileForm,
    } = props
    useEffect(() => {
        if (showTableFor.for == 'agency') {
            props.requestAgencyAgents(
                showTableFor.id
                // state.page,
                // state.pageSize,
                // sorted
            )
        } else if (showTableFor.for == 'advisor') {
            props.requestAgentClients(showTableFor.id)
        }
    }, [showTableFor])

    const [searchValue, setSearchValue] = useState('')

    if (showTableFor.for == 'agency') {
        if (agents.data) {
            return (
                <>
                    <Row>
                        <Col span={3}>Advisors</Col>
                        <Col span={15}>
                            <Text
                                style={{
                                    color: '#00BAC9',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setShowProfileForm({
                                        for: 'advisor',
                                        type: 'new',
                                    })
                                }
                            >
                                Add new
                            </Text>
                        </Col>
                        <Col span={6}>
                            <Input
                                addonBefore={<SearchOutlined rev={undefined} />}
                                placeholder="Refine advisors"
                                onChange={(event) => {
                                    setSearchValue(event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                // rowSelection={{
                                //     type: 'checkbox',
                                //     ...rowSelection,
                                // }}
                                style={{ cursor: 'pointer' }}
                                columns={[
                                    {
                                        title: 'First Name',
                                        dataIndex: 'firstName',
                                        // render: (text: string) => <a>{text}</a>,
                                    },
                                    {
                                        title: 'Last Name',
                                        dataIndex: 'lastName',
                                    },
                                    {
                                        title: 'Phone',
                                        dataIndex: 'phone',
                                    },
                                    {
                                        title: 'Email',
                                        dataIndex: 'email',
                                    },
                                ]}
                                dataSource={agents.data}
                                onRow={(record: any, rowIndex: any) => {
                                    return {
                                        onClick: (event: any) => {
                                            setShowProfile({
                                                id: parseInt(
                                                    record.id.toString()
                                                ),
                                                for: 'advisor',
                                            })
                                        },
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </>
            )
        } else {
            return <Spin size="large" />
        }
    } else {
        if (clients.data) {
            return (
                <>
                    <Row>
                        <Col span={3}>Clients</Col>
                        <Col span={15}>
                            <Text
                                style={{
                                    color: '#00BAC9',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setShowProfileForm({
                                        type: 'new',
                                        for: 'client',
                                        advisorId: showTableFor.id,
                                        // id: advisor.data.id,
                                    })
                                }
                            >
                                Add new
                            </Text>
                        </Col>
                        <Col span={6}>
                            <Input
                                addonBefore={<SearchOutlined rev={undefined} />}
                                placeholder="Refine clients"
                                onChange={(event) => {
                                    setSearchValue(event.target.value)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Table
                                // rowSelection={{
                                //     type: 'checkbox',
                                //     ...rowSelection,
                                // }}
                                style={{ cursor: 'pointer' }}
                                columns={[
                                    {
                                        title: 'First Name',
                                        dataIndex: 'firstName',
                                        // render: (text: string) => <a>{text}</a>,
                                    },
                                    {
                                        title: 'Last Name',
                                        dataIndex: 'lastName',
                                    },
                                    {
                                        title: 'Phone',
                                        dataIndex: 'phone',
                                    },
                                    {
                                        title: 'Email',
                                        dataIndex: 'email',
                                    },
                                ]}
                                dataSource={clients.data}
                                onRow={(record: any, rowIndex: any) => {
                                    return {
                                        onClick: (event: any) => {
                                            setShowProfile({
                                                id: parseInt(
                                                    record.id.toString()
                                                ),
                                                for: 'client',
                                            })
                                        },
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </>
            )
        } else {
            return <Spin size="large" />
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        agents: state.agents,
        clients: state.clients,
    }
}

export default connect(mapStateToProps, {
    requestAgencyAgents,
    requestAgentClients,
})(ProfileTable)

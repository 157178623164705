import { call, put, take, takeLatest } from 'redux-saga/effects'

import * as actions from '../actions'
import { BASE_IC_DATA } from '../components/shared_components/constants'
import { incomeCompService } from '../services/incomeComp.service'

const {
    INCOME_COMPS_GET_REQUEST,
    INCOME_COMPS_GET_SUCCESS,
    INCOME_COMPS_GET_FAILURE,
    INCOME_COMP_GET_REQUEST,
    INCOME_COMP_GET_SUCCESS,
    INCOME_COMP_GET_FAILURE,
    INCOME_COMP_UPDATE_REQUEST,
    INCOME_COMP_CREATE_REQUEST,
    INCOME_COMP_TAX_CALC_UPDATE_REQUEST,
    INCOME_COMP_SET_CLIENT_REQUEST,
    INCOME_COMP_RESET_REQUEST,
    successUpdateIncomeComp,
    failedUpdatedIncomeComp,
    successUpdateIncomeCompTaxCalc,
    failedUpdatedIncomeCompTaxCalc,
    successResetIncomeComp,
    successCreateIncomeComp,
    failedCreateIncomeComp,
    successSetIncomeCompClient,
    successAlert,
    processErrorMessage,
} = actions

export function* getIncomeComps() {
    while (true) {
        const { page, perPage, sorted, filters } = yield take(
            INCOME_COMPS_GET_REQUEST
        )
        try {
            const response = yield call(incomeCompService.getAllIncomeComps, {
                page,
                perPage,
                sorted,
                filters,
            })
            yield put({ type: INCOME_COMPS_GET_SUCCESS, ...response })
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put({ type: INCOME_COMPS_GET_FAILURE, error })
        }
    }
}

export function* getIncomeComp() {
    while (true) {
        const { incomeCompId } = yield take(INCOME_COMP_GET_REQUEST)
        try {
            const response = yield call(
                incomeCompService.getIncomeComp,
                incomeCompId
            )
            yield put({ type: INCOME_COMP_GET_SUCCESS, ...response.data })
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put({ type: INCOME_COMP_GET_FAILURE, error })
        }
    }
}

export function* updateIncomeComp() {
    while (true) {
        const { incomeCompId, payload } = yield take(INCOME_COMP_UPDATE_REQUEST)
        try {
            const response = yield call(
                incomeCompService.updateIncomeComp,
                incomeCompId,
                payload
            )
            yield put(successUpdateIncomeComp(response.data))
            yield put(successAlert('Income Comparison updated successfully'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedUpdatedIncomeComp(error))
        }
    }
}

export function* setIncomeCompClient() {
    while (true) {
        const { customer } = yield take(INCOME_COMP_SET_CLIENT_REQUEST)
        yield put(successSetIncomeCompClient(customer))
    }
}

export function* resetIncomeComp() {
    while (true) {
        yield take(INCOME_COMP_RESET_REQUEST)
        yield put(successResetIncomeComp(BASE_IC_DATA))
    }
}

function* incomeTaxCalcUpdate(action) {
    const { incomeCompId, payload } = action
    try {
        yield call(
            incomeCompService.updateIncomeCompTaxCalc,
            incomeCompId,
            payload
        )
        yield put(successUpdateIncomeCompTaxCalc())
    } catch (error) {
        yield put(processErrorMessage(error))
        yield put(failedUpdatedIncomeCompTaxCalc(error))
    }
}

export function* updateIncomeCompTaxCalc() {
    yield takeLatest(INCOME_COMP_TAX_CALC_UPDATE_REQUEST, incomeTaxCalcUpdate)
}

export function* createIncomeComp() {
    while (true) {
        const { payload } = yield take(INCOME_COMP_CREATE_REQUEST)
        try {
            const response = yield call(
                incomeCompService.createIncomeComp,
                payload
            )
            yield put(successCreateIncomeComp(response.data))
            payload.history.push(`/income-comps/${response.data.data.id}`)
            yield put(successAlert('Income Comparison created successfully'))
        } catch (error) {
            yield put(processErrorMessage(error))
            yield put(failedCreateIncomeComp(error))
        }
    }
}

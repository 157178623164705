import axios from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { BASE_URL } from '../config/env'

const logoutUser = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
}

const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken')
    const data = await request.post('/api/v1/auth-refresh/', {
        token: refreshToken,
    })
    localStorage.setItem('token', data.token)
}

export const request = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    withCredentials: true,
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
})

request.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token')
        if (token) {
            config['headers']['Authorization'] = `JWT ${token}`
        }
        if (config['headers']['Content-Type'] === 'application/json') {
            config.data = decamelizeKeys(config.data, {
                split: /(?=[A-Z])|(?=(?<![1-9_])[1-9])/,
            })
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(
    async (response) => {
        return camelizeKeys(response)
    },
    async (error) => {
        const originalConfig = error.config
        if (
            !['/api/v1/auth-refresh/', '/api/v1/auth/'].includes(
                originalConfig.url
            ) &&
            error.response
        ) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    await refreshToken()
                    return request(originalConfig)
                } catch (err) {
                    logoutUser()
                    return Promise.reject(err)
                }
            }
        } else {
            return Promise.reject(error)
        }
    }
)

export const get = async (resource, params, options = {}) => {
    return await request({
        method: 'get',
        url: resource,
        config: request.config,
        ...params,
        ...options,
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export const post = async (resource, payload, options = {}) => {
    return await request({
        method: 'post',
        url: resource,
        data: payload,
        config: request.config,
        ...options,
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export const put = async (resource, payload, options = {}) => {
    return await request({
        method: 'put',
        url: resource,
        data: payload,
        config: request.config,
        ...options,
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export const _delete = async (resource, options = {}) => {
    // an underscore is used to avoid conflict with the delete function in the axios library
    return await request({
        method: 'delete',
        url: resource,
        config: request.config,
        ...options,
    })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export const handleError = (error) => {
    if (error.hasOwnProperty('response')) throw error.response.data.errorMessage
    if (error.hasOwnProperty('message')) throw error.message
    throw error
}

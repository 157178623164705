import { INCOME_COMPS_GET_FAILURE, INCOME_COMPS_GET_REQUEST, INCOME_COMPS_GET_SUCCESS } from '../actions'

export default function incomeComps(state = { loading: false, count: 0, perPage: 35, current: 0, sorted: undefined }, action) {
  switch (action.type) {
    case INCOME_COMPS_GET_REQUEST:
      return { loading: true, count: 0, perPage: 35, current: 0, sorted: undefined };
    case INCOME_COMPS_GET_SUCCESS:
      return { loading: false, ...action.data };
    case INCOME_COMPS_GET_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

import { STATES_GET_REQUEST, STATES_GET_SUCCESS, STATES_GET_FAILURE } from "../actions";

export default function states(state = { loading: true, data: [] }, action) {
  switch (action.type) {
    case STATES_GET_REQUEST:
      return { loading: true, data: [] };
    case STATES_GET_SUCCESS:
      return { loading: false, data: action.data };
    case STATES_GET_FAILURE:
      return { loading: false, error: action.error };
    default:
      return state;
  }
}

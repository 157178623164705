import { isEmpty } from 'lodash'

import * as api from './api'

export const incomeCompService = {
  getAllIncomeComps,
  getIncomeComp,
  createIncomeComp,
  updateIncomeComp,
  updateIncomeCompTaxCalc,
};


function getAllIncomeComps({ page, perPage, sorted, filters }) {
  if (isEmpty(sorted)) {
    sorted = null;
  }
  return api
    .get("/api/v1/income-comps/", {
      params: { page: page, per_page: perPage, sorted: sorted, filters: filters }
    })
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function getIncomeComp(incomeCompId) {
  return api
    .get(`/api/v1/income-comps/${incomeCompId}/`)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function createIncomeComp(payload) {
  return api
    .post("/api/v1/income-comps/", {...payload, customer_id: payload.customer.id})
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function updateIncomeComp(incomeCompId, payload) {
  return api
    .put(`/api/v1/income-comps/${incomeCompId}/`, payload)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}

function updateIncomeCompTaxCalc(incomeCompId, payload) {
  return api
    .put(`/api/v1/income-comps/${incomeCompId}/tax-calc/`, payload)
    .then(response => {
      return response;
    })
    .catch(api.handleError);
}
